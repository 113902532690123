<template>
   <div id="app">
        <router-view ></router-view>  
    </div>                  
</template>

<script>

    export default {
        name: 'app',
        components: {},
        data () {
            return {

            }
        },
        computed: {
           
        },
        created: function() {
            
            
        },
        methods: {
                  
        }
    }
</script>

<style scoped>

    /* #app {
        display: flex;
    } */

    .bg-gray {
        background: #f4f4f4;
    }

    .banner {
        width: 100%;
        margin-top: 9em;
    }

</style>
