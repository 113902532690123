<template>

    <div id="comprovante-box">
        <el-row :gutter="30">
            <el-col :span="24" v-if="activeItem.name !== 'Segunda via do comprovante de compra'">
                <span class="forma-pgto">Possui o comprovante da compra?:</span>
                <el-select v-model="values.possuiComprovante" placeholder="-- Selecione uma Opção --">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" v-if="!values.possuiComprovante">
                <input  v-model="values.comprovante.cpf" placeholder="CPF do comprador" class="input"/>
            </el-col>
            <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" v-if="!values.possuiComprovante">
                <input  v-model="values.comprovante.nome" placeholder="Nome do comprador" class="input"/>
            </el-col>
        </el-row>
        <el-row :gutter="30">
            <el-col ::xs="24" :sm="24" :md="14" :lg="14" :xl="14" class="loja-compra">
                <input  v-model="values.comprovante.loja" placeholder="Loja da compra" class="input "/>
            </el-col>
            <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" class="loja-imaginarium-form">
                <span>Você pode consultar nossa lista de lojas <a v-scroll-to="{ el: '#lojas' }">clicando aqui</a></span>
            </el-col>
        </el-row>

    </div>
    
</template>

<script>
    export default {
        name: 'comprovante-box',
        components: {},
        props: ['values', 'activeItem'],
        data () {
            return {
                options: [{
                    value: true,
                    label: 'SIM'
                    }, {
                    value: false,
                    label: 'NÃO'
                }],
            }
        },
        computed: {
           
        },
        created: function() {
            
            
        },
        methods: {
           
        }
    }
</script>

<style scoped>
    .loja-imaginarium-form {
        margin-top: 1em;
    }
</style>
