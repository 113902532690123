<template>

    <div id="imaginarium">

        <Topbar image="https://ms.imgnet.com.br/sac/resources/images/LogoImg.png" :showMenu="true"/>

        <!-- <img class="banner" src="https://ms.imgnet.com.br/sac/resources/images/banner.png" alt="Banner"> -->
       <!-- <el-row>
            <el-col :span="24" class="bg-gray">
                <Depoimentos />
            </el-col>
        </el-row> -->
        <el-row>
            <el-col :span="24">
                <NovoAtendimento />
            </el-col>
        </el-row>
  
        <el-row>
            <el-col :span="24" class="bg-gray">
                <Manuais />
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <Lojas />
            </el-col>
        </el-row>

        <!-- <el-row>
            <el-col :span="24" class="bg-gray">
                <AcompanharAtendumento />
            </el-col>
        </el-row> -->

   
        
    </div>
    
</template>

<script>
    import Topbar from './Topbar.vue'
    import Depoimentos from '../pages/Depoimentos/CarrouselDepoimentos.vue'
    import Manuais from '../pages/Manuais/Manuais.vue'
    import NovoAtendimento from '../pages/NovoAtendimento/NovoAtendimento.vue'
    import Lojas from '../pages/Lojas/Lojas.vue'
    import PerguntasFrequentes from '../pages/PerguntasFrequentes/PerguntasFrequentes.vue'
    import AcompanharAtendumento from '../pages/AcompanharAtendimento/AcompanharAtendimento.vue'

    export default {
        name: 'imaginarium',
        components: {Topbar, Depoimentos,Manuais,NovoAtendimento,Lojas,PerguntasFrequentes,AcompanharAtendumento},
        data () {
            return {

            }
        },
        computed: {
           
        },
        created: function() {
            
            
        },
        methods: {
                  
        }
    }
</script>

<style scoped>

    /* #imaginarium {
        flex: 1
    } */

    .bg-gray {
        background: #f4f4f4;
    }

    .banner {
        width: 100%;
        margin-top: 9em;
    }

        img {
        display: block;
        margin: 1.5em auto 1.5em auto;
    }

    .el-menu, .top-menu, .el-menu--horizontal{
        position: fixed;
        width: 100%;
        display: flex;
        background-color: #FFF;
        z-index: 2;
    }

    .top-menu-item {
        text-align: center;
        flex: 1;
    }

    .el-menu-item.is-active {
        border-bottom: transparent;
        color: #000; 
    }

    .line {
        border: .6px solid #b1b3b5;
        margin-top: 1px;
    }

    #topbar {
        position: fixed;
        width: 100%;
        z-index: 3;
        background-color: #FFF;
    }

</style>
