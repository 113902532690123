<template>
    <div id="acompanhar-atendimento">
        <h1 class="title item">ACOMPANHE SEU ATENDIMENTO</h1>
        <h2 class="subtitle">As interações completas do ticket devem ser acompanhadas diretamente no e-mail cadastrado na abertura do seu atendimento.</h2>
        <el-row class="item" :gutter="30">
            <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
                <input  v-model="cpf" placeholder="CPF" class="input" v-mask="'###.###.###-##'"/>
            </el-col>
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
                <el-button icon="el-icon-search" v-loading="isLoading" @click="search">Pesquisar</el-button>
            </el-col>
        </el-row>
        <div v-if="err">
            {{err}}
        </div>
        <div v-if="atendimentos.length" class="table">
            <el-table
                :data="atendimentos"
                stripe
                style="width: 100%">
                <el-table-column
                prop="DATA_INICIO"
                label="Abertura">
                </el-table-column>
                <el-table-column
                prop="TITULO"
                label="Processo">
                </el-table-column>
                <el-table-column
                prop="STATUS"
                label="Status">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'acompanhar-atendimento',
    data() {
        return {
            cpf: '',
            isLoading: false,
            atendimentos: [],
            err: ''
        }
    },
    methods: {
        search: function(){
            var self = this;

            self.err = ''

            if(!self.cpf)
                return self.err = 'Preencha seu CPF'

            self.isLoading = true

            this.$http.get(`https://ms.imgnet.com.br/api.sac/atendimentos/${self.cpf.replace(/[^\w\s]/gi, '')}`)
                .then(response => {
                    self.isLoading = false

                    if(!response.data.success){
                        return self.err = 'Ocorreu um erro ao buscar os atendimentos! Tente novamente mais tarde.'
                    }

                    if(!response.data.data || !response.data.data.length){
                        console.log(response.data.data)
                        return self.err = 'Nenhum atendimento encontrado.'
                    }
                    console.log('ok')
                    self.atendimentos = response.data.data
                })
                .catch(err => {
                    self.isLoading = false
                    self.err = 'Ocorreu um erro ao buscar os atendimentos! Tente novamente mais tarde.'
                })
        }
    }
}
</script>

<style>
h1 {
    font-size: 1.2em;
}
#acompanhar-atendimento {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}

#acompanhar-atendimento .item {
    flex: 1
}

#acompanhar-atendimento .input {
    height: 2em;
}

#acompanhar-atendimento .table {
    width: 85%;
}

#acompanhar-atendimento .subtitle {
    text-align: center;
    font-weight: normal;
    margin-bottom: 2em;
    font-size: 1em;
}

</style>
