<template>

    <div id="novo-atendimento">
       <!-- <h1 class="title" v-if="!finished">FALE CONOSCO:</h1> -->
        <div id="NeoassistCentral"></div>
<!--        <el-row :gutter="20" v-if="!finished">
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
                <p></p>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
                <div class="btn" :class="(this.btnActive === 'fisica' ? 'btn-active' : '')">
                    <el-button v-scroll-to="{ el: '#novo-atendimento' }" @click="changeActiveButton('fisica')"><span class="btn-title">Loja Física</span></el-button>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
                <div class="btn" :class="(this.btnActive === 'virtual' ? 'btn-active' : '')">
                    <el-button v-scroll-to="{ el: '#novo-atendimento' }" @click="changeActiveButton('virtual')"><span class="btn-title">Loja Virtual</span></el-button>
                </div>
            </el-col>
        </el-row>

        <el-row class="form">
            <el-col :span="20" :offset="2">

                <FormNovoAtendimento 
                    :origin="this.btnActive"
                />

            </el-col>
        </el-row> -->

    </div>
    
</template>

<script>
    import FormNovoAtendimento from './FormNovoAtendimento.vue'

    export default {
        name: 'novo-atendimento',
        components: {FormNovoAtendimento},
        data () {
            return {
                btnActive: '',
                finished: false
            }
        },
        computed: {

        },
        created: function() {
            var self = this;

            self.$root.$on('FINISHED', (payload) => {
                self.finished = payload;
            })

            self.$root.$on('START_ATENDIMENTO', (payload) => {
                self.btnActive = payload.origem;
                
                setTimeout(() => {
                    self.$root.$emit('CHANGE_ACTIVEID', payload.codFormulario)
                }, 200)
                
            })
            
        },
        methods: {
            changeActiveButton: function(newActive){
                this.btnActive = newActive;
            }
        }
    }
</script>

<style scoped>
    #novo-atendimento {
        margin-bottom: 2em;
        margin-top: 11em;
    }

    .title {
        text-align: center;
        margin-top: 1.5em;
        margin-bottom: .5em;
        font-size: 1.2em;
    }

    .btn {
        display: grid;
        height: 5em;
        width: 100%;
        margin-top: 1em;
    }

    .btn-title {
        font-weight: bold;
        font-size: 1.5em;
    }

    .el-button {
        border-radius: 0px;
        background-color: #9df1e1;
        border: 4.43px solid #9df1e1;
        width: 100%;
    }

    .btn-active .el-button {
        border-radius: 0px;
        background-color: #ffffff;
        border: 4.43px solid #9df1e1;
    }

    .el-button, .el-button:focus, .el-button:hover {
        color: #000;
    }

    .form {
        margin-top: 2em;
        margin-bottom: 2em;
    }
</style>
