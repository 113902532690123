<template>

    <div id="produtos-ecommerce-box">

        <el-row v-loading="loadingPedidos">
            <el-col :span="16">
                <input  v-model="values.pedidoVtex" placeholder="Código do pedido" class="input" />
            </el-col>
             <el-col :span="8">
                 <div class="btn-pesquisar" @click="this.onPesquisarPedidos">
                     <span>Pesquisar</span>
                 </div>
            </el-col>
        </el-row>

        <el-row v-if="values.produtos.length" v-for="produto in values.produtos" class="produtos-container" :key="produto.cod">
            <el-col :xs="9" :sm="9" :md="9" :lg="6" :xl="6">
                <img :src="`https://imagens.imgnet.com.br/f/IMAGINARIUM/${produto.cod}/thumb`" alt="Thumbnail do produto" class="thumb">
            </el-col>
            <el-col :xs="15" :sm="15" :md="15" :lg="18" :xl="18">
                
                <el-row>
                    <el-col :span="24">
                        <div class="produto-desc">
                            {{ `${produto.cod} - ${produto.desc}` }}
                        </div>                        
                    </el-col>
                    <el-col :span="12">
                        <el-col :span="24">
                            <div class="produto-quantidade">
                                Quantidade: <span class="input-number"><el-input-number :min="0" :max="999" v-model="produto.qtde"></el-input-number></span>
                            </div>
                        </el-col>
                    </el-col>
                    <el-col :span="12">
                        <div class="produto-quantidade">
                            <el-select v-model="produto.motivo" placeholder="Motivo">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="produto-detalhes">
                            <textarea placeholder="Mensagem" class="textarea" v-model="produto.detalhes"/>                      
                        </div>                        
                    </el-col>
                </el-row>
                
            </el-col>
        </el-row>

    </div>
    
</template>

<script>

    export default {
        name: 'produtos-ecommerce-box',
        components: {},
        props: ['values'],
        data () {
            return {
                loadingPedidos: false,
                options: [
                    {
                        value: 'desistencia',
                        label: 'Desistência'
                    },{
                        value: 'avaria',
                        label: 'Avaria'
                    },{
                        value: 'extravio',
                        label: 'Extravio'
                    },{
                        value: 'roubo',
                        label: 'Roubo'
                    }
                ]
            }
        },
        computed: {
           
        },
        created: function() {

        },
        methods: {
            onPesquisarPedidos: function(){
                var self = this;

                self.loadingPedidos = true;

                self.axios.post('https://api8008.imgnet.com.br/fusion/getNFsECM', {nf_pedido: self.values.pedidoVtex.trim()})
                    .then(response => {
                        if(!response.data.success) { self.loadingPedidos = false; return console.error(response.data) }
                            
                        if(response.data.data && response.data.data[0] && response.data.data[0].PEDIDO){

                        
                        
                        self.values.produtosPedido = response.data.data;
                        self.values.produtos = response.data.data.map(produto => {
                            return {
                                qtde: 0,
                                cod: produto.PRODUTO,
                                desc: produto.DESC_PRODUTO,
                                dataCompra: produto.DATA,
                                motivo: '',
                                detalhes: ''
                            }
                        })
                        self.values.pedidoECM = response.data.data[0].PEDIDO

                        self.values.tipoPagamento = response.data.data[0].TIPO_PAGAMENTO

                        alert('Pedido encontrado, pode prosseguir com a abertura do atendimento!')

                        }else{
                            alert('Pedido não encontrado, verifique o código do pedido informado!')
                        }
                        
                        self.loadingPedidos = false;

                    })
                    .catch(err => {
                        console.error(err)
                        self.loadingPedidos = false;
                    })
            }
        }
    }
</script>

<style scoped>
    .btn-pesquisar {
        height: 3em;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 0px;
        border: 1px solid #D2D7D3;
        background-color: #eae8e8;
        box-shadow: 5px 3px 10px 0px rgba(0,0,0,.1);
        margin-left: 1em;
        cursor: pointer;
    }

    .btn-pesquisar:active {
        background-color: rgb(179, 179, 179);
    }

    .thumb {
        width: 10em;
    }

    .input-number {
        padding-left: 1em;
    }

    .produtos-container {
        background-color: #eae8e8;
        padding: 1em;
        margin-bottom: 1em;
        display: flex;
        align-items: center;
    }

    .produto-desc {
        margin-bottom: 1em;
        text-align: center;
        font-weight: bold;
    }

    .produto-quantidade, .produto-motivo, .produto-detalhes {
        justify-content: center;
        display: flex;
    }

    .textarea {
        height: 8em;
        width: 90%;
        border: none;
        border-bottom: 1px solid rgba(175, 175, 175, 0.7);
        margin-top: 1em;
        font-size: 1.2em;
        font-family: 'Montserrat';
    }
</style>
