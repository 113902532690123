<template>

    <div id="manuais">
        <h1 class="title">MANUAIS DOS PRODUTOS</h1>
        <h2 class="subtitle">Perdeu o manual? Não se preocupe, faça o download da versão digital.</h2>
        <span id="lojas"></span>
        <el-row class="row">
            <el-col :span="16" :offset="4">
                <el-input
                    placeholder="vitrola de mala mapa"
                    suffix-icon="el-icon-search"
                    v-model="filter">
                </el-input>
            </el-col>

            <!-- <el-col :span="16" :offset="4">
                <p>Não sabe o nome do produto? <a href="#">Clique aqui</a></p>
            </el-col> -->
        </el-row>
        <el-row class="row">
            <el-col :span="18" :offset="3" v-if="manuaisFilter.length">
                <el-col :span="3">
                    <div class="arrow" @click="indexDown">
                        <i class="el-icon-arrow-left"></i>
                    </div>
                </el-col>
                <el-col :span="18">
                    <div class="manuais-container">
                        <div v-if="manuaisFilter[index]" class="manual"> <a :href="manuaisFilter[index].link" target="_blank">{{manuaisFilter[index].produto}}</a> </div>
                        <div v-if="manuaisFilter[index + 1]" class="manual"> <a :href="manuaisFilter[index + 1].link" target="_blank">{{manuaisFilter[index + 1].produto}}</a> </div>
                    </div>
                </el-col>
                <el-col :span="3">
                    <div class="arrow" @click="indexUp">
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </el-col>
            </el-col>
            <el-col :span="18" :offset="3" v-if="!manuaisFilter.length">
                <div class="manuais-container">Não encontramos nenhum manual com este nome :(</div>
            </el-col>
        </el-row>
    </div>
    
</template>

<script>

    export default {
        name: 'manuais',
        components: {},
        data () {
            return {
                manuais: [],
                index: 0,
                filter: ''
            }
        },
        computed: {
           manuaisFilter: function(){
                if(!this.filter)
                    return this.manuais;
                
                this.index = 0;

                return this.manuais.filter(manual => {
                    return new RegExp(this.filter, 'i').test(manual.produto) ||new RegExp(this.filter, 'i').test(manual.codProduto)
                });
                
           }
        },
        created: function() {
            this.getManuais();            
        },
        methods: {
            getManuais: function(){
                var self = this;

                this.$http.get('https://ms.imgnet.com.br/api.sac/manuais')
                    .then(response => {
                        self.manuais = response.data.data.map(manual => {
                            return {
                                produto: manual.DESC_PRODUTO,
                                codProduto: manual.PRODUTO,
                                link: `https://drive.google.com/file/d/${manual.ID}/view?usp=sharing`
                            }
                        })
                    })
                    .catch(err => {

                    })
            },

            indexUp: function() {
                if(this.index < this.manuaisFilter.length -1){
                    this.index++;
                }                
            },

            indexDown: function(){
                if(this.index > 0){
                    this.index--;
                }                
            }
        }
    }
</script>

<style>

    #manuais .title {
        text-align: center;
        margin-top: 2em;
        margin-bottom: 1.5em;
        font-size: 1.2em;
    }

    #manuais .subtitle {
        text-align: center;
        font-weight: normal;
        margin-bottom: 2em;
        font-size: 1em;
    }

    #manuais .el-input__inner {
        padding-right: 30px;
        border: 0;
        border-bottom: 1px solid #888;
        height: 2em;
        font-size: 1.22em;
        border-radius: 0;
        background-color: transparent;
    }

    #manuais .el-input__suffix {
        color: #000;
    }

    #manuais .el-input__icon {
        font-size: 2em;
        width: 1em;
    }

    #manuais a {
        color: #444;
        text-decoration: none;
    }

    #manuais .row {
        padding-bottom: 1em;
    }

    #manuais .manuais-container {
        display: flex;
        margin-top: 1em;
        justify-content: space-around;
    }

    #manuais .manual {
        padding: 1em;
        background-color: #DADFE1;
        border-radius: 2em;
    }

    #manuais .arrow {
        display: flex;
        justify-content: center;
        padding: 2em;
        cursor: pointer;
    }

</style>
