<template>

    <div id="loja">
        
        <p><b>{{filial.FILIAL}}</b> <span v-if="filial.TIPO != 'Imaginarium'">({{filial.TIPO}})</span></p>
        {{`${filial.ENDERECO}, ${filial.NUMERO} ${filial.COMPLEMENTO ? `, ${filial.COMPLEMENTO}` : ''} - ${filial.BAIRRO}`}}
        <br />
        {{`Cep: ${filial.CEP}`}} {{filial.TELEFONE ? `| ${filial.TELEFONE}` : ''}}
        <p>
            <i class="el-icon-location"></i> <a @click="onClick">Ver no mapa</a>
        </p>

        <hr />
    </div>
    
</template>

<script>

    export default {
        name: 'loja',
        props: ['data'],
        components: {},
        data () {
            return {

            }
        },
        computed: {
            filial: function(){
                var self = this

                return {
                    CODIGO: self.data.CODIGO,
                    FILIAL: self.captalizeText(self.data.FILIAL),
                    ENDERECO: self.captalizeText(self.data.ENDERECO),
                    NUMERO: self.data.NUMERO,
                    CEP: self.data.CEP,
                    COMPLEMENTO: self.captalizeText(self.data.COMPLEMENTO),
                    BAIRRO: self.captalizeText(self.data.BAIRRO),
                    TELEFONE: self.data.TELEFONE,
                    LAT: self.data.LAT,
                    LNG: self.data.LNG,
                    TIPO: self.captalizeText(self.data.TIPO)
                }
            }
        },
        created: function() {
            
        },
        methods: {
            captalizeText: function(text){
                if(text) {
                    return text.replace(/\w\S*/g, (txt) => {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})
                }
                
                return ''

            },

            onClick: function(){
                this.$parent.$emit('clickPanelLojas', {
                    lat: this.filial.LAT,
                    lng: this.filial.LNG
                })
            }
        }
    }
</script>

<style scoped>
    #loja, a {
        color: #FFF
    }

    a {
        cursor: pointer;
        text-decoration: underline;
    }

    hr {
        border: 1px solid #8c8c8c;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-right: 3em;
    }
</style>
