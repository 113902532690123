<template>

    <div id="faq-lv">

        <h2 @click="setActiveItemFAQ('produtos')" class="row">Produtos</h2>
        <el-collapse v-model="activeProdutos" accordion v-if="activeItemFAQ == 'produtos'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Todos os produtos disponíveis nas lojas físicas, também são encontrados na loja virtual? </span>
                </template>
                <div>Alguns produtos e marcas vendidas na loja virtual são exclusivos, ou seja, não estarão disponíveis nos estoques das nossas franquias além de que alguns produtos podem esgotar mais rapidamente em uma das duas lojas.</div>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">Onde encontro as especificações do produto?</span>
                </template>
                <div>Ao acessar a página de um produto na nossa <a href="https://loja.imaginarium.com.br/" target="_blank">loja virtual</a>, logo abaixo de sua foto são apresentadas as especificações do produto no campo “Detalhes” e “Informação Adicional”.</div>
            </el-collapse-item>
            <el-collapse-item name="3">
                <template slot="title">
                    <span class="title">Onde posso tirar dúvidas sobre um produto?</span>
                </template>
                <div>Ao acessar a página de um produto na nossa <a href="https://loja.imaginarium.com.br/" target="_blank">loja virtual</a>, logo abaixo de suas especificações é apresentada uma área de perguntas e respostas onde você pode fazer perguntas e verificar comentários, dúvidas e avaliações de outros consumidores.</div>
            </el-collapse-item>
            <el-collapse-item name="4">
                <template slot="title">
                    <span class="title">Qual o prazo de garantia do meu produto?</span>
                </template>
                <p>Todos os produtos Imaginarium possuem um prazo de garantia legal de 90 dias, contados a partir da data de compra, conforme o Código de Defesa do Consumidor. A garantia dos produtos cobre apenas defeitos de fabricação, ou seja, itens avariados (quebrados, trincados, lascados, rachados, etc) não receberão direito a análise ou troca.</p>
            </el-collapse-item>
            <el-collapse-item name="5">
                <template slot="title">
                    <span class="title">A Imaginarium realiza personalização de produtos?</span>
                </template>
                <div>Não. Os produtos da Imaginarium são exclusivos.</div>
            </el-collapse-item>
        </el-collapse>

        <!---->

        <h2 @click="setActiveItemFAQ('compras')" class="row">Compras</h2>
        <el-collapse v-model="activeCompras" accordion v-if="activeItemFAQ == 'compras'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Como faço para comprar os produtos da loja virtual?</span>
                </template>
                <p>Para fazer sua compra é preciso que tenha um cadastro em nosso site, caso ainda não tenha, <a href="https://loja.imaginarium.com.br/login" target="_blank">clique aqui</a>. Nosso site lhe proporciona um ambiente virtual seguro e protegido.</p>
                <p>Feito o cadastro, agora é só você navegar pela loja virtual, selecionar os produtos do seu interesse e finalizar a compra.</p>
                <p>Você pode consultar nosso passo-a-passo <a href="https://loja.imaginarium.com.br/institucional/como-comprar" target="_blank">clicando aqui</a>.</p>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">O preço de venda dos produtos é o mesmo em todas as lojas da Imaginarium? </span>
                </template>
                <div>Lojas físicas são franquias da marca Imaginarium e podem apresentar variações no preço dos produtos, conforme a região, não ultrapassando 20% do valor, ou seja, o preço pode variar entre as lojas físicas e também na nossa loja virtual. </div>
            </el-collapse-item>
            <el-collapse-item name="3">
                <template slot="title">
                    <span class="title">Posso comprar na loja virtual e retirar em uma loja física?</span>
                </template>
                <div>Essa opção ainda não está disponível para todo o Brasil. Para saber se já está disponível na sua cidade basta simular uma compra em nossa loja virtual e informar o seu CEP no carrinho de compras.</div>
            </el-collapse-item>
        </el-collapse>

        <!---->

        <h2 @click="setActiveItemFAQ('cadastro')" class="row">Cadastro</h2>
        <el-collapse v-model="activeCadastro" accordion v-if="activeItemFAQ == 'cadastro'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Esqueci minha senha. O que devo fazer?</span>
                </template>
                <div>Não se preocupe! Nós podemos gerar uma nova senha de acesso. <a href="http://loja.imaginarium.com.br/login?ReturnUrl=%2faccount" target="_blank">clique aqui</a>, selecione a opção “Entrar com e-mail e senha” e em seguida clique no campo “Esqueci minha senha” e informe o e-mail cadastrado em nossa loja virtual. Após a solicitação, em instantes você receberá em seu e-mail uma nova senha de acesso.</div>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">Desejo mudar meu endereço de entrega. Onde faço essa alteração?</span>
                </template>
                <p>Para alterar seus dados, efetue seu login no site <a href="http://loja.imaginarium.com.br/login?ReturnUrl=%2faccount" target="_blank">clicando aqui</a>. Em seguida, clique em “SUA CONTA” e selecione a opção “MEU CADASTRO”. Feito isso, o endereço poderá ser alterado.</p>
                <p>Para os pedidos já efetuados, entre em contato com a nossa <a v-scroll-to="{ el: '#novo-atendimento' }"><span @click="goToAtendimento('virtual', 7)">Central de Atendimento</span></a> e verifique se ainda há possibilidade de alterar o endereço de entrega. </p>
            </el-collapse-item>
            <el-collapse-item name="3">
                <template slot="title">
                    <span class="title">Cadastrei o meu e-mail errado. O que devo fazer?</span>
                </template>
                <p>Neste caso, como não será possível receber nenhum e-mail pelo nosso sistema para alterar qualquer informação, será preciso realizar um novo cadastro pela loja virtual. Mas se você já fez uma compra com um e-mail incorreto e deseja acompanha-la, basta entrar em contato com a nossa <a v-scroll-to="{ el: '#novo-atendimento' }">Central de Atendimento</a>.</p>
            </el-collapse-item>
        </el-collapse>

        <!---->

        <h2 @click="setActiveItemFAQ('pagamento')" class="row">Pagamento</h2>
        <el-collapse v-model="activePagamento" accordion v-if="activeItemFAQ == 'pagamento'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Quais as formas de pagamento da loja virtual?</span>
                </template>
                <p>Você pode pagar seu pedido através de Boleto Bancário ou através dos cartões de crédito Visa ou MasterCard em até 6 (seis) vezes sem juros*. </p>
                <p>*Parcelas mínimas de R$25,00 (vinte e cinco reais)</p>
                <p>Confira nossa Política de Pagamento e Envio <a href="http://loja.imaginarium.com.br/institucional/pagamento-e-envio" target="_blank">clicando aqui</a>. </p>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">Recebi um boleto com vencimento para 3 (três) dias. Devo esperar o vencimento para pagar?</span>
                </template>
                <div>Você pode pagar o boleto de seu pedido assim que finalizar a sua compra. O quanto antes pagar, mais rápido será a liberação para o envio do mesmo. Caso a data de vencimento caia em feriados ou final de semana, o prazo se estenderá até o próximo dia útil.</div>
            </el-collapse-item>
            <el-collapse-item name="3">
                <template slot="title">
                    <span class="title">Efetuei o pagamento do meu pedido via boleto. Como faço para enviar o comprovante?</span>
                </template>
                <div>Se você realizou o pagamento através do boleto, não é necessário enviar comprovante. A instituição bancária fará a liberação automática em até 2 (dois) dias úteis, após o pagamento.</div>
            </el-collapse-item>
            <el-collapse-item name="4">
                <template slot="title">
                    <span class="title">Meu pedido ainda não teve pagamento aprovado. O que devo fazer?</span>
                </template>
                <p>Caso seu pagamento tenha sido efetuado via Cartão de Crédito e o seu pedido ainda esteja com o status “Pagamento Pendente”, entre em contato com a operadora do seu cartão e confirme o pagamento da sua compra.</p>
                <p>Caso seu pagamento tenha sido efetuado via Boleto Bancário e o seu pedido ainda esteja com o status “Pagamento Pendente”, aguarde por até 03 (três) úteis após o pagamento para confirmação. </p>
            </el-collapse-item>
            <el-collapse-item name="5">
                <template slot="title">
                    <span class="title">Como devo utilizar o meu cupom de desconto?</span>
                </template>
                <p>Para utilizar o seu voucher de desconto você deve prosseguir da seguinte maneira:</p>
                <p>Após receber o seu voucher, entre na nossa <a href="https://loja.imaginarium.com.br/" target="_blank">loja virtual</a>, acesse a sua conta informando o seu nome de usuário e senha e, uma vez logado, inicie suas compras normalmente. </p>
                <p>Após adicionar os produtos no seu carrinho, digite o código de desconto no campo “cupom de desconto” que aparece logo abaixo da lista de produtos selecionados para compra e clique em “Adicionar”. Automaticamente o desconto será aplicado nos produtos selecionados, mostrando o valor final da compra. Depois, é só clicar em “fechar pedido” e preencher os dados do seu cartão de crédito ou optar pelo boleto para finalizar a compra.</p>
                <p>Atenção: seu voucher é pessoal e intransferível, sendo válido para uma única utilização. O desconto é válido apenas para produtos vendidos e entregues pela Imaginarium. Sempre confira se todos os valores foram corretamente descontados antes de finalizar a compra. Após realizada, a compra não poderá ter seus valores alterados.</p>
            </el-collapse-item>
            <el-collapse-item name="6">
                <template slot="title">
                    <span class="title">Como devo utilizar o meu vale presente?</span>
                </template>
                <p>Para utilizar o seu vale presente você deve prosseguir da seguinte maneira:</p>
                <p>Após receber o seu cupom de desconto, entre na nossa <a href="https://loja.imaginarium.com.br/" target="_blank">loja virtual</a>, acesse a sua conta informando o seu nome de usuário e senha e, uma vez logado, inicie suas compras normalmente.</p>
                <p>Após adicionar os produtos no seu carrinho, clique em “fechar pedido” e será direcionado para a página de pagamento. Você deve clicar na opção “adicionar vale presente”, que está em cima dos campos “cartão de crédito” e “boleto bancário”, informar o código do vale presente e clicar em “adicionar”. Após finalizar a sua compra.</p>
                <p>Atenção: eu voucher é pessoal e intransferível, sendo válido para uma única utilização e para compras com entrega em seu endereço. O desconto é válido apenas para produtos vendidos e entregues pela Imaginarium. Sempre confira se todos os valores foram corretamente descontados antes de finalizar a compra. Após realizada, a compra não poderá ter seus valores alterados. 
                O voucher NÃO é válido para a categoria de compras online com retirada em loja física.</p>
            </el-collapse-item>
        </el-collapse>

        <!---->

        <h2 @click="setActiveItemFAQ('pedido')" class="row">Pedido</h2>
        <el-collapse v-model="activePedido" accordion v-if="activeItemFAQ == 'pedido'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Como faço para acompanhar o status de entrega do meu pedido?</span>
                </template>
                <div>Para acessar os seus pedidos e acompanha-los <a href="http://loja.imaginarium.com.br/account/orders" target="_blank">clique aqui</a> e faça seu login. Em seguida clique em "MEUS PEDIDOS", selecione o pedido desejado, clique em "VER DETALHES DO PEDIDO" e posteriormente em "EXIBIR DADOS DE RASTREIO". Em casos excepcionais o código da rastreio poderá demorar até 5 (cinco) dias para estar disponível.</div>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">Como faço um pedido para presente?</span>
                </template>
                <div>Para fazer um pedido para enviar como presente é simples! Basta selecionar os produtos que deseja enviar como presente e selecionar a opção “Para presente” na tela de finalização de seu pedido. É importante ter todos os dados de quem irá receber o presente, como nome completo, nome da rua, CEP, número da casa ou apartamento, para que seu pedido seja entregue corretamente. </div>
            </el-collapse-item>
            <el-collapse-item name="3">
                <template slot="title">
                    <span class="title">Pedidos para presente podem ser enviados sem o valor da compra?</span>
                </template>
                <div>Não. Por uma exigência legal, o envio de um pedido deve acompanhar a nota fiscal original com todos os dados da compra. Esse é um procedimento exigido pela Secretaria da Fazenda de Santa Catarina, onde fica nossa Central de Distribuições.</div>
            </el-collapse-item>
        </el-collapse>

        <!---->

        <h2 @click="setActiveItemFAQ('troca')" class="row">Troca</h2>

        <div v-if="activeItemFAQ == 'troca'">
            <p>A troca de produtos ocorrerá apenas em caso de avaria por transporte ou defeito de fabricação havendo disponibilidade em estoque. </p>
            <p>Para troca de cor, tamanho ou modelo adquirido erroneamente, será possível apenas a opção de reembolso para posterior novo pedido.</p>
            <p>Os produtos comprados na nossa loja virtual só poderão ser trocados na própria loja virtual, pois o estoque e os procedimentos fiscais são diferentes entre nossa rede de franquias e nosso e-commerce.</p>
        </div>
        
        <el-collapse v-model="activeTroca" accordion v-if="activeItemFAQ == 'troca'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Como efetuar uma troca quando recebi um pedido com avaria ou diferente do que eu pedi?</span>
                </template>
                <p>
                    <b>1. Fale com a gente -</b>
                    <span>Entre em contato com nossa <a v-scroll-to="{ el: '#novo-atendimento' }">Central de Atendimento</a> e relate a divergência encontrada nos produtos comprados na loja virtual. Esse contato deve ser realizado em até 7 (sete) dias corridos após recebimento do produto.</span>
                </p>
                <p>
                    <b>2. Nos envie o produto -</b>
                    <span>O frete é por nossa conta, você receberá da nossa Central de Atendimento uma senha de envio dos Correios.</span>
                    <span>O produto com avaria ou divergente do pedido deve ser enviado com a cópia da Nota Fiscal, Manual, todos os acessórios sem indícios de uso, sem violação do lacre original/etiqueta e na embalagem original.</span>
                </p>
                <p>
                    <b>3. A análise da Imaginarium -</b>
                    <span>A equipe de Qualidade da Imaginarium analisará seu produto. Poderá haver reprova da troca pelos seguintes motivos: indícios de uso; desgaste em decorrência de uso; ausência da avaria apontada.</span>
                </p>
                <p>
                    <b>4. A Autorização da troca -</b>
                    <span>A autorização de troca será confirmada em até 10 (dez) dias úteis após análise doproduto recebido.</span>
                    <span>Caso o produto escolhido não esteja mais disponível em estoque, você poderá optar pelo cancelamento da compra.</span>
                </p>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">Como efetuar uma troca quando meu produto apresentou um defeito de fabricação?</span>
                </template>
                <p>
                    <b>1. Fale com a gente -</b>
                    <span>Entre em contato com nossa <a v-scroll-to="{ el: '#novo-atendimento' }">Central de Atendimento</a> e relate o ocorrido com o produto comprado na loja virtual. Esse contato deve ser realizado em até 90 (noventa) dias corridos após recebimento do produto.</span>
                </p>
                <p>
                    <b>2. Nos envie o produto -</b>
                    <span>O frete é por nossa conta, você receberá da nossa Central de Atendimento uma senha de envio dos Correios.</span>
                    <span>O produto com defeito deve ser enviado com a cópia da Nota Fiscal, manual, e todos os acessórios.</span>
                </p>
                <p>
                    <b>3. A análise da Imaginarium -</b>
                    <span>A equipe de Qualidade da Imaginarium analisará seu produto. Poderá haver reprova da troca pelos seguintes motivos: avaria no produto; falta de acessórios; ausência do defeito apontado. </span>
                </p>
                <p>
                    <b>4. A Autorização da troca -</b>
                    <span>A autorização de troca será confirmada em até 10 (dez) dias úteis após análise doproduto recebido</span>
                    <span>Caso o produto escolhido não esteja mais disponível em estoque, você poderá optar pelo cancelamento da compra.</span>
                </p>
            </el-collapse-item>
        </el-collapse>

        <!---->

        <h2 @click="setActiveItemFAQ('arrependimento')" class="row">Arrependimento/Desistência</h2>
        
        <el-collapse v-model="activeDesistencia" accordion v-if="activeItemFAQ == 'arrependimento'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Quero devolver um produto, o que faço?</span>
                </template>
                <div>
                    <b>1. Fale com a gente</b>
                    <p>a)	Antes da entrega do pedido</p>
                    <span>Caso você se arrependa da compra realizada antes da entrega do pedido, basta recusar o recebimento da mercadoria no ato da entrega e entrar em contato com a nossa <a v-scroll-to="{ el: '#novo-atendimento' }">Central de Atendimento</a> para iniciar o processo de devolução do valor pago. O valor pago será integralmente devolvido, sem custo adicional, assim que o pacote retornar à Imaginarium.</span>
                    <p>b)	Após o recebimento do pedido</p>
                    <span>Caso você se arrependa da compra realizada após o recebimento do pedido, o registro de desistência deverá ser realizado junto a nossa <a v-scroll-to="{ el: '#novo-atendimento' }">Central de Atendimento</a> em até 7 (sete) dias corridos após o recebimento.</span>
                </div>
                <p>
                    <b>2. Nos envie o produto -</b>
                    <span>O frete é por nossa conta, você receberá da nossa Central de Atendimento uma senha de envio dos Correios.</span>
                    <span>O produto deve ser enviado com a cópia da Nota Fiscal, Manual, todos os acessórios sem indícios de uso ou avarias e na embalagem original.</span>
                </p>
                <p>
                    <b>3. A análise da Imaginarium -</b>
                    <span>A equipe de Qualidade da Imaginarium analisará seu produto. Poderá haver reprova da devolução pelos seguintes motivos: indícios de uso e/ou desgaste em decorrência de uso da mercadoria; avaria; falta de acessório; embalagem danificada.</span>
                </p>
                <p>
                    <b>4. A Autorização da devolução -</b>
                    <span>A autorização de devolução será confirmada em até 10 (dez) dias úteis após análise do produto recebido.</span>
                </p>
                <div>
                    <b>5. Restituição do valor -</b>
                    <span>Após a análise da equipe da Qualidade sobre o produto enviado a devolução do dinheiro será realizada da seguinte maneira: </span>
                    <ul>
                        <li><b>Pagamento com cartão de crédito:</b> O estorno será realizado em até 10 (dez) dias úteis após a liberação do produto pelo setor responsável pela análise. O valor poderá ser visualizado em até duas faturas subsequentes, dependendo da data de vencimento do cartão.</li>
                        <li><b>Pagamento com boleto bancário:</b> O reembolso será realizado em até 10 (dez) dias úteis após a liberação do produto pelo setor responsável pela análise na conta corrente indicada pelo cliente.</li>
                    </ul>
                    <p><b>Observação 1:</b> O valor pago pela mercadoria será devolvido ao titular da compra, registrado no pagamento do pedido.</p>
                    <p><b>Observação 2:</b> No caso de presentes, a pessoa que receber o pedido poderá optar por receber um vale presente no valor total da compra. </p>
                </div>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">Como efetuar uma troca quando meu produto apresentou um defeito de fabricação?</span>
                </template>
                <p>
                    <b>1. Fale com a gente -</b>
                    <span>Entre em contato com nossa <a v-scroll-to="{ el: '#novo-atendimento' }">Central de Atendimento</a> e relate o ocorrido com o produto comprado na loja virtual. Esse contato deve ser realizado em até 90 (noventa) dias corridos após recebimento do produto.</span>
                </p>
                <p>
                    <b>2. Nos envie o produto -</b>
                    <span>O frete é por nossa conta, você receberá da nossa Central de Atendimento uma senha de envio dos Correios.</span>
                    <span>O produto com defeito deve ser enviado com a cópia da Nota Fiscal, manual, e todos os acessórios.</span>
                </p>
                <p>
                    <b>3. A análise da Imaginarium -</b>
                    <span>A equipe de Qualidade da Imaginarium analisará seu produto. Poderá haver reprova da troca pelos seguintes motivos: avaria no produto; falta de acessórios; ausência do defeito apontado. </span>
                </p>
                <p>
                    <b>4. A Autorização da troca -</b>
                    <span>A autorização de troca será confirmada em até 10 (dez) dias úteis após análise do produto recebido.</span>
                    <span>Caso o produto escolhido não esteja mais disponível em estoque, você poderá optar pelo cancelamento da compra.</span>
                </p>
            </el-collapse-item>
        </el-collapse>

        <!---->

        <h2 @click="setActiveItemFAQ('geral')" class="row">Geral</h2>
        <el-collapse v-model="activeGeral" accordion v-if="activeItemFAQ == 'geral'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Como dar uma sugestão para a Imaginarium?</span>
                </template>
                <div>Para passar sua opinião ou sugestão entre em contato com a nossa <a v-scroll-to="{ el: '#novo-atendimento' }"><span @click="goToAtendimento('virtual', 7)">central de atendimento</span></a></div>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">Como obter informações para realizar meu trabalho escolar/acadêmico?</span>
                </template>
                <div>Todas as informações sobre a Imaginarium poderão ser obtidas através do endereço de e-mail marketing@imaginarium.com.br</div>
            </el-collapse-item>
            <el-collapse-item name="3">
                <template slot="title">
                    <span class="title">Vocês vendem seus produtos para colocar em minha loja, fora do sistema de franquias?</span>
                </template>
                <div>Sim. Caso tenha interesse em se tornar um parceiro, <a href="http://portal.imaginarium.com.br/namedida/fale-conosco" target="_blank">clique aqui</a> e preencha seu cadastro.</div>
            </el-collapse-item>
            <el-collapse-item name="4">
                <template slot="title">
                    <span class="title">Como oferecer o produto e/ou o serviço de minha empresa para Imaginarium?</span>
                </template>
                <div>Para oferecer seus serviços para a Imaginarium, você deve encaminhar uma mensagem para o e-mail fornecedores@imaginarium.com.br</div>
            </el-collapse-item>
            <el-collapse-item name="5">
                <template slot="title">
                    <span class="title">Como faço para me tornar um franqueado?</span>
                </template>
                <div>Por meio da página <a href="http://portal.imaginarium.com.br/seja-um-franqueado/" target="_blank">seja um franqueado</a>, você conhecerá melhor como funciona a franquia Imaginarium e poderá realizar seu cadastro.</div>
            </el-collapse-item>
            <el-collapse-item name="6">
                <template slot="title">
                    <span class="title">Como participar de um processo seletivo da Imaginarium?</span>
                </template>
                <div>Para fazer parte do time Imaginarium, acesse a página <a href="https://site.vagas.com.br/imaginarium" target="_blank">trabalhe conosco</a> e cadastre seu currículo. Caso surja uma oportunidade com o seu perfil, a empresa entrará em contato.</div>
            </el-collapse-item>
        </el-collapse>

        <!---->

        <h2 @click="setActiveItemFAQ('astec')" class="row">Assistência técnica</h2>
        <el-collapse v-model="activeASTEC" accordion v-if="activeItemFAQ == 'astec'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Como funciona a Assistência Técnica Imaginarium?</span>
                </template>
                <div>A Imaginarium conta com uma assistência técnica autorizada que realiza o conserto de grande parte dos nossos produtos eletrônicos. Para obter maiores informações sobre a possibilidade de conserto do seu item, entre em contato com a nossa <a v-scroll-to="{ el: '#novo-atendimento' }"><span @click="goToAtendimento('virtual', 5)">central de atendimento</span></a> e preencha o cadastro com todas as informações solicitadas. Será feita uma análise de disponibilidade da assistência técnica. Caso haja, informaremos a forma de envio e o possível custo desta operação.</div>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">Posso consertar meu produto Imaginarium por conta própria?</span>
                </template>
                <div>A Imaginarium trabalha para oferecer a melhor qualidade em seus produtos, por isso recomenda que todos os reparos sejam realizados pela Assistência Técnica Autorizada da marca. Reparos fora do agente autorizado podem invalidar a garantia do produto.</div>
            </el-collapse-item>
            <el-collapse-item name="3">
                <template slot="title">
                    <span class="title">Onde posso comprar peças de reposição originais?</span>
                </template>
                <div>Peças para reposição de alguns produtos Imaginarium estão disponíveis em nossa Assistência Técnica autorizada. A solicitação deve ser feita em nossa <a v-scroll-to="{ el: '#novo-atendimento' }"><span @click="goToAtendimento('virtual', 6)">central de atendimento</span></a>. Será feita uma análise de disponibilidade de envio da parte/peça do seu produto. Caso haja, informaremos a forma de envio e o possível custo desta operação.</div>
            </el-collapse-item>
            <el-collapse-item name="4">
                <template slot="title">
                    <span class="title">Tenho dúvidas na utilização do produto, o que devo fazer?</span>
                </template>
                <div>Consulte o manual de produto para mais informações sobre a utilização correta do modelo que você adquiriu. Caso não tenha o manual, baixe a versão em PDF que está disponível na seção de <a v-scroll-to="{ el: '#novo-atendimento' }">manuais</a>. Caso não encontre o arquivo correspondente ao seu produto faça sua solicitação em nossa <a v-scroll-to="{ el: '#novo-atendimento' }"><span @click="goToAtendimento('virtual', 3)">central de atendimento</span></a></div>
            </el-collapse-item>
        </el-collapse>
         
    </div>
    
</template>

<script>

    export default {
        name: 'faq-lf',
        components: {},
        data () {
            return {
                activeProdutos: '',
                activeCompras: '',
                activeGeral: '',
                activeCadastro: '',
                activePagamento: '',
                activePedido: '',
                activeTroca: '',
                activeDesistencia: '',
                activeASTEC: '',
                activeItemFAQ: ''
            }
        },
        computed: {
           
        },
        created: function() {
            
            
        },
        methods: {
            goToAtendimento: function(origem, codFormulario){
                this.$root.$emit('START_ATENDIMENTO', {origem, codFormulario});
            },

            setActiveItemFAQ: function(item){
                this.activeItemFAQ = item
            }
        }
    }
</script>

<style scoped>
    #faq-lv {
        margin-bottom: 5em;
    }

    .title {
        font-weight: bold;
    }

    a {
        cursor: pointer;
        color: #7741bb;
        text-decoration: underline;
    }

    .row {
        cursor: pointer;
        border-bottom: 1px solid #D2D7D3;
        padding-bottom: 1em;
    }
</style>
