<template>

    <div id="depoimentos">
        <h1 class="title">ESTÃO DIZENDO POR AÍ...</h1>

        <div class="block">
            <el-carousel trigger="click" :interval="7000">
                <el-carousel-item v-for="depoimento in this.depoimentos" :key="depoimento.id">
                    <Depoimento :data="depoimento" />
                </el-carousel-item>
            </el-carousel>            
        </div>
    </div>
    
</template>

<script>
    import Depoimento from './Depoimento.vue';
    import { Loading } from 'element-ui';

    export default {
        name: 'depoimentos',
        components: {Depoimento},
        data () {
            return {
                depoimentos: []
            }
        },
        computed: {
           
        },
        created: function() {
            this.getDepoimentos();
            
        },
        methods: {
            getDepoimentos: function() {
                var self = this

                this.$http.get('https://ms.imgnet.com.br/api.sac/depoimentos')
                    .then((response) => {
                        self.depoimentos = response.data.map(depoimento => {
                            return {
                                mensagem: depoimento.DEPOIMENTO,
                                autor: depoimento.NOME_CLIENTE,
                                data: depoimento.DATA,
                                uf: depoimento.ESTADO_CLIENTE
                            }
                        })
                    })
                    .catch((err) => {
                        console.error(err)
                    })

            }
        }
    }
</script>

<style>
    #depoimentos .title {
        text-align: center;
        margin-top: 1em;
        margin-bottom: 1em;
        font-size: 1em;
    }

    #depoimentos {
       margin-top: 11em;
    }

    .el-carousel__container {
        height: 12em;
    
    }

    @media only screen and (max-device-width: 480px){
        .el-carousel__container {
            height: 45em;
        }

    }
    
</style>
