<template>

    <div id="novo-atendimento">
        <div class="top-bar-conteiner">
            <div class="contato__item_title">
                <h1 class="title" v-if="!finished"><span>Fale</span> conosco :</h1>
            </div>
            Obrigado pelo interesse de entrar em contato conosco.<br/>Preencha os campos e nos envie sua mensagem.
        </div>
        <el-row :gutter="20" v-if="!finished">
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
                <p></p>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
                <div class="btn" :class="(this.btnActive === 'fisica' ? 'btn-active' : '')">
                    <el-button v-scroll-to="{ el: '#novo-atendimento' }" @click="changeActiveButton('fisica')" class="puket-round-btn"><span class="btn-title">Loja Física</span></el-button>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
                <div class="btn" :class="(this.btnActive === 'virtual' ? 'btn-active' : '')">
                    <el-button v-scroll-to="{ el: '#novo-atendimento' }" @click="changeActiveButton('virtual')" class="puket-round-btn"><span class="btn-title">Loja Virtual</span></el-button>
                </div>
            </el-col>
        </el-row>

        <el-row class="form">
            <el-col :span="20" :offset="2" :class="{'brand-custom-form':this.btnActive}">

                <FormNovoAtendimento 
                    :origin="this.btnActive"
                />

            </el-col>
        </el-row>

    </div>
    
</template>

<script>
    import FormNovoAtendimento from './FormNovoAtendimento.vue'

    export default {
        name: 'novo-atendimento',
        components: {FormNovoAtendimento},
        data () {
            return {
                btnActive: '',
                finished: false
            }
        },
        computed: {

        },
        created: function() {
            var self = this;

            self.$root.$on('FINISHED', (payload) => {
                self.finished = payload;
            })

            self.$root.$on('START_ATENDIMENTO', (payload) => {
                self.btnActive = payload.origem;
                
                setTimeout(() => {
                    self.$root.$emit('CHANGE_ACTIVEID', payload.codFormulario)
                }, 200)
                
            })

            document.title = "SAC Puket"
            
        },
        methods: {
            changeActiveButton: function(newActive){
                this.btnActive = newActive;
            }
        }
    }
</script>

<style scoped>

    .title span{
        font-size: 1.5em;
        font-weight: bold;
    }

    .puket-round-btn{
        border-radius: 60px;
    }

    #novo-atendimento {
        margin-bottom: 2em;
        /* background-color: #22BBEF */
    }

    .contato__item_title {
        font-family: 'Fontastique',sans-serif ;
        display: flex;
        position: relative;
        width: 300px;
        height: 50px;
        background-color: #f3e82d;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        padding: 0 25px 0 0;
        margin: 30px 10px 65px 0px;
        border-radius: 0px 5px 30px 0px;
        box-shadow: 2px 3px 2px 2px blueviolet;
    }

    .title {
        text-align: center;
        /* margin-top: 1.5em; */
        margin-bottom: .5em;
        font-size: 1.2em;
        text-align: center;
        color: #6819cd;
    }

    .btn {
        display: grid;
        height: 5em;
        width: 100%;
        margin-top: 1em;
    }

    .btn-title {
        font-weight: bold;
        font-size: 1.5em;
    }

    #puket .el-button {
        color : #f8ef24 !important;
        background-color: #6819cd;
        border: 4.43px solid #6f23cf;
        width: 100%;
    }

    #puket .btn-active .el-button {
        color : #FFF !important;
        background-color: mediumslateblue;
        border: 4.43px solid #6f23cf;
    }

    .el-button, .el-button:focus, .el-button:hover {
        color: #000;
    }

    .form {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    .contato__item_title {
        /* background-color: #22BBEF */ 
    }

    
</style>

<style>
    /* Global changes on children */

    #puket{
        background-color:#22BBEF;
        height: 100vh;
        overflow-y: auto;
        overflow-x:hidden;

    }

    @font-face {
       font-family: Fontastique;
       src: url("./lib/font/fontastique/fontastique.ttf") format("truetype")
   }

    #puket *{
        font-family: Fontastique,sans-serif;
    }


    #puket .input{
        border-radius: 50px;
        padding: 0px 10px;
        box-sizing: border-box;
    }

    #puket textarea.input {
        border-radius: 30px;
        padding: 5px 20px;
        width: 100%;
        overflow-y: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */
    }
    #puket textarea.input::-webkit-scrollbar { 
        /* WebKit */
            width: 0;
            height: 0;
    }

    #puket .btn-pesquisar{
        border-radius: 50px !important;
        margin-left: 0 !important;
    }
    #puket span.puket-text {
        color: #FFF;
    }
    #puket .back {
        cursor: pointer;
        color: #FFF;
    }

    #puket .item{
        border-bottom: 1px solid rgba(200, 200, 200, 0.7);
    }

    #puket .legenda {
        color: #FFF;
    }

    #puket .loja-imaginarium-form {
        display: none;
        margin-top: 1em;
        color: #999;
    }

    #puket .puket-round-btn {
        border-radius: 60px;
        color : #f8ef24 !important;
        background-color: #6819cd;
        border: 4.43px solid #6f23cf;
        width: 100%;
    }

    #puket .btn-active .el-button {
        color : black !important;
        border-radius: 60px;
        background-color: #ffffff;
        border: 4.43px solid #6f23cf;
    }

    #puket span.forma-pgto {
        color: #FFF;
    }

    #puket .el-input__inner{
        border-radius: 30px;
    }

    #puket .loja-compra {
        width: 100%;
    }

    #puket .brand-custom-form {
        background-color: rgb(10,100,200,0.3);
        margin: 10px 10% ;
        padding: 10px 0px ;
        border-radius: 80px;

    }

    #puket .brand-custom-form #form-novo-atendimento .el-col:empty {
        background-color: #F00;
    }

    #puket .top-bar-conteiner {
        color: #FFF;
        display:flex;
        flex-direction: row;
        align-items: flex-end;
        font-size:1.3em;
    }
</style>
