<template>

    <div id="depoimento">
        
        <el-row>
            <el-col :span="16" :offset="4">
                <img src="https://ms.imgnet.com.br/sac/resources/images/aspas.png" alt="Logotipo">
                <h4 class="mensagem">{{data.mensagem}}</h4>
                <hr />
                <h5>{{data.autor}}, {{data.uf}}</h5>
                <p>{{data.data}}</p>
            </el-col>
        </el-row>

    </div>
    
</template>

<script>

    export default {
        name: 'depoimento',
        props: ['data'],
        components: {},
        data () {
            return {

            }
        },
        computed: {
           
        },
        created: function() {
            
            
        },
        methods: {
                  
        }
    }
</script>

<style>
    #depoimento h4 {
        font-weight: 400;
        font-size: .8em;
    }

    #depoimento p {
        margin: 0;
    }

    #depoimento h5 {
        margin-bottom: 0;
    }

    #depoimento hr {
        border: 2px solid #0a0a0a;
        width: 3%;
        margin-left: 0;
    }

    #depoimento img {
        position: absolute;
        margin-left: -3em;
        margin-top: 1em;
    }

    .el-carousel__button {
        background-color: white;
        width: 10px;
        height: 10px;
        border-radius: 1em;
        margin: 3px;
        border: 2px solid black;
    }

</style>
