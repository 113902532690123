<template>

    <div id="lojas-container">
        
        <h1 class="title">PROCURE A LOJA MAIS PRÓXIMA DE VOCÊ</h1>

        <el-col :span="16" :offset="4">
            <el-input :autofocus="true"
                placeholder="digite seu estado ou cidade"
                suffix-icon="el-icon-search"
                v-model="searchFilial">
            </el-input>
        </el-col>

        <el-col :span="16" :offset="4" class="filtros">
            <el-checkbox v-model="showImaginarium">Imaginarium</el-checkbox>
            <el-checkbox v-model="showLovebrands">Lovebrands</el-checkbox>
            <el-checkbox v-model="showMultimarcas">Multimarcas</el-checkbox>
        </el-col>

        <div class="panel-lojas scroll">
            <div v-for="filial in getFiliais" :key="filial.CODIGO + filial.TIPO">
                <Loja :data="filial" />
            </div>
        </div>

        <div class="map">
            <gmap-map
                :center="center"
                :zoom="zoom"
                :options="{styles: mapStyles}"
                style="height: 35em;"
            >
                <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    icon="https://ms.imgnet.com.br/sac/resources/images/pin.png"
                    @click="centerMap(m)"
                ></gmap-marker>
            </gmap-map>
        </div>

    </div>
    
</template>

<script>
    import Loja from './Loja.vue'

    export default {
        name: 'lojas',
        components: {Loja},
        offset: -210,
        data () {
            return {
                center: {lat:-27.5928035, lng:-48.5683001},
                zoom: 14,
                searchFilial: '',
                filiais: [],
                showImaginarium: true,
                showLovebrands: true,
                showMultimarcas: true
            }
        },
        computed: {
           mapStyles: function(){
                return [
                {
                    elementType: 'geometry',
                    stylers: [{color: '#c9c9c9'}]
                },
                {
                    elementType: 'labels.icon',
                    stylers: [{visibility: 'off'}]
                },
                {
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#616161'}]
                },
                {
                    elementType: 'labels.text.stroke',
                    stylers: [{color: '#f5f5f5'}]
                },
                {
                    featureType: 'administrative.land_parcel',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#bdbdbd'}]
                },
                {
                    featureType: 'poi',
                    elementType: 'geometry',
                    stylers: [{color: '#eeeeee'}]
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#757575'}]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{color: '#e5e5e5'}]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#9e9e9e'}]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{color: '#ffffff'}]
                },
                {
                    featureType: 'road.arterial',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#757575'}]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [{color: '#dadada'}]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#616161'}]
                },
                {
                    featureType: 'road.local',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#9e9e9e'}]
                },
                {
                    featureType: 'transit.line',
                    elementType: 'geometry',
                    stylers: [{color: '#e5e5e5'}]
                },
                {
                    featureType: 'transit.station',
                    elementType: 'geometry',
                    stylers: [{color: '#eeeeee'}]
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{color: '#b3b3b3'}]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#9e9e9e'}]
                }
                ]
           },

           getFiliais: function() {         
               var self = this;

               var filiais = self.filiais;

               filiais = filiais.filter(function(filial){

                   if(filial.TIPO == 'IMAGINARIUM' && self.showImaginarium) return true
                   if(filial.TIPO == 'LOVEBRANDS' && self.showLovebrands) return true
                   if(filial.TIPO == 'MULTIMARCAS' && self.showMultimarcas) return true

                   return false
               })

                if(self.searchFilial.length >= 3){
                    return filiais.filter(filial => {
                        var filtrable = self.removerAcentos(self.searchFilial);

                        return new RegExp(filtrable, 'i').test(filial.FILIAL) || RegExp(filtrable, 'i').test(filial.CIDADE) || new RegExp(filtrable, 'i').test(filial.ENDERECO) || new RegExp(filtrable, 'i').test(filial.UF) || new RegExp(filtrable, 'i').test(filial.DESC_UF) || new RegExp(filtrable, 'i').test(filial.BAIRRO)
                    })
                }

                
                return filiais

           },

           markers: function(){
               return this.getFiliais.map(filial => {
                   return {
                       position: {lat: parseFloat(filial.LAT), lng: parseFloat(filial.LNG)},
                       filial: filial
                   }
               })
           }
        },

        created: function() {
            this.loadFiliais();

            this.$on('clickPanelLojas', function (newPosition) {
                this.center = {lat: parseFloat(newPosition.lat - 0.002), lng: parseFloat(newPosition.lng - 0.03)}
            })
            // this.$nextTick(() => {
            //     this.$scrollTo(this, 3000,  { offset: 210 });
            // })
            
        },
        
        methods: {
            loadFiliais: function() {
                var self = this;

                this.$http.get('https://ms.imgnet.com.br/api.sac/lojas')
                    .then((response) => {
                        self.filiais = response.data;
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            },

            centerMap: function(loja){
                this.center = {
                    lat: loja.position.lat - 0.002,
                    lng: loja.position.lng - 0.03
                }

                this.searchFilial = loja.filial.FILIAL

                this.zoom = 14
            },

            removerAcentos: function(text){
                return text ? text.normalize('NFD').replace(/[\u0300-\u036f]/g, "") : ''
            }
        }
    }
</script>

<style>

    .gm-bundled-control-on-bottom, 
    .gm-fullscreen-control, 
    .gmnoprint {
        display: none;
    }

    #lojas-container-container {
        margin-top: 3em;
        margin-bottom: 4em;
    }

    #lojas-container .map {
        margin-top: 13em;
    }

    #lojas-container .title {
        text-align: center;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        font-size: 1.2em;
    }

    #lojas-container .el-input__inner {
        padding-right: 30px;
        border: 0;
        border-bottom: 1px solid #888;
        height: 2em;
        font-size: 1.5em;
        border-radius: 0;
    }

    #lojas-container .el-input__suffix {
        color: #000;
    }

    #lojas-container .el-input__icon {
        font-size: 2em;
        width: 1em;
    }

    #lojas-container .panel-lojas {
        background-color: #7741bb;
        height: 36em;
        width: 26em;
        position: absolute;
        z-index: 1;
        margin-top: 9em;
        margin-left: 11em;
        padding: 1em;
        overflow-y: scroll;
    }

    #lojas-container .scroll::-webkit-scrollbar {
        background-color: transparent;
    }
    #lojas-container .scroll::-webkit-scrollbar-thumb  {
        background-color: #461a7c;
        height: 4em;
        border-radius: 100px;
    }

    #lojas-container .filtros {
        display: flex;
        justify-content: center;
        margin-top: 1em;
    }

    #lojas-container .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #7741bb;
        border-color: #7741bb;
    }

    #lojas-container .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #7741bb;
    }


</style>
