<template>

    <div>
        <h1 class="title">PERGUNTAS FREQUENTES</h1>

        <el-row :gutter="20" class="btn-group">
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
                <p></p>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
                <div class="btn" :class="(this.btnActive === 'FISICA' ? 'btn-active' : '')">
                    <el-button v-scroll-to="{ el: '#faq' }" @click="changeActiveButton('FISICA')"><span class="btn-title">Loja Física</span></el-button>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
                <div class="btn" :class="(this.btnActive === 'VIRTUAL' ? 'btn-active' : '')">
                    <el-button v-scroll-to="{ el: '#faq' }" @click="changeActiveButton('VIRTUAL')"><span class="btn-title">Loja Virtual</span></el-button>
                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="16" :offset="4">
                <div v-if="this.btnActive === 'FISICA'">
                    <FAQLojaFisica />
                </div>
                <div v-if="this.btnActive === 'VIRTUAL'">
                    <FAQLojaVirtual />
                </div>
            </el-col>
        </el-row>

    </div>
    
</template>

<script>
    import FAQLojaFisica from './FAQLojaFisica.vue'
    import FAQLojaVirtual from './FAQLojaVirtual.vue'

    export default {
        name: 'faq',
        components: {FAQLojaFisica, FAQLojaVirtual},
        data () {
            return {
                btnActive: ''
            }
        },
        computed: {
           
        },
        created: function() {
            
            
        },
        methods: {
            changeActiveButton: function(newActive){
                this.btnActive = newActive;
            }
        }
    }
</script>

<style scoped>
    .title {
        text-align: center;
        margin-top: 1.5em;
        margin-bottom: .5em;
        font-size: 1.2em;
    }

    .btn {
        display: grid;
        height: 5em;
        margin-top: 1em;
    }

    .btn-title {
        font-weight: bold;
        font-size: 1.5em;
    }

    .el-button {
        border-radius: 0px;
        background-color: #9df1e1;
        border: 4.43px solid #9df1e1;
        width: 100%;
    }

    .btn-active .el-button {
        border-radius: 0px;
        background-color: #ffffff;
        border: 4.43px solid #9df1e1;
    }

    .el-button, .el-button:focus, .el-button:hover {
        color: #000;
    }

    .btn-group {
        padding-bottom: 2em;
    }

    #faq {
        padding-bottom: 4em;
    }
    
</style>
