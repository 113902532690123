<template>

    <div id="form-builder">

        <el-row :gutter="30">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <input  v-model="values.cpf" placeholder="CPF" class="input" v-mask="'###.###.###-##'"/>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <input  v-model="values.nomeCompleto" placeholder="Nome Completo" class="input"/>
            </el-col>
        </el-row>

        <el-row :gutter="30">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <input  v-model="values.email" placeholder="E-mail" class="input"/>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <input  v-model="values.emailCheck" placeholder="Digite seu e-mail novamente" class="input"/>
            </el-col>
        </el-row>

        <el-row :gutter="30">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <input  v-model="values.telefone" placeholder="Telefone" class="input" v-mask="['(##) ####-####', '(##) #####-####']"/>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <input  v-model="values.telefoneDois" placeholder="Telefone (Adicional)" class="input" v-mask="['(##) ####-####', '(##) #####-####']"/>
            </el-col>
        </el-row>

        <el-row v-if="activeItem.fields && activeItem.fields.includes('pedidoLojaVirtual')">
            <el-col :span="24">
                <input  v-model="values.pedidoVtex" placeholder="Código do pedido" class="input"/>
            </el-col>
        </el-row>

        <el-row :gutter="30" v-if="activeItem.fields && activeItem.fields.includes('pecaSolicitada')">
            <el-col :xs="24" :sm="24" :md="19" :lg="19" :xl="19">
                <input  v-model="values.pecaSolicitada" placeholder="Peça solicitada" class="input"/>
            </el-col>
            <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
                <input  v-model="values.pecaSolicitadaQuantidade" placeholder="Quantidade" class="input"/>
            </el-col>
        </el-row>

        <el-row v-if="activeItem.fields && activeItem.fields.includes('formaPagamento')" class="row">
            <el-col :span="24">
                <span class="forma-pgto">Forma de pagamento:</span>
                <el-select v-model="values.tipoPagamento" placeholder="-- Selecione uma Opção --">
                    <el-option
                    v-for="item in optionsPagamento"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
        </el-row>

        <el-row :gutter="20" v-if="activeItem.fields && activeItem.fields.includes('lojaMaisProxima')">
            <el-col :span="14">
                <el-select v-model="values.lojaImaginariumMaisProxima" filterable @change="changeLojaMaisProxima" placeholder="Loja Imaginarium mais próxima" no-match-text="Nenhuma loja encontrada com este nome" no-data-text="Nenhuma loja encontrada com este nome">
                    <el-option
                    v-for="item in lojas"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="10" class="loja-imaginarium-form">
                <span>Você pode consultar nossa lista de lojas <a v-scroll-to="{ el: '#lojas' }">clicando aqui</a></span>
            </el-col>
        </el-row>

        <el-row v-if="activeItem.fields && activeItem.fields.includes('comprovanteBox')" class="row" >
            <el-col :span="24">
                <ComprovanteBox
                    :values="values"
                    :activeItem="activeItem"
                />
            </el-col>
        </el-row>

        <el-row class="row" v-if="activeItem.fields && activeItem.fields.includes('produtoBox')">
            <el-col :span="22" :offset="1">
                <ProdutosBox 
                    :produtos="values.produtos"
                    :onNovoProduto="this.onNovoProduto"
                    :onRemoveProduto="this.onRemoveProduto"
                    :mostrarQuantidade="this.activeItem.name != 'Solicitação de acessórios'"
                />
            </el-col>
        </el-row>

        <el-row class="row" v-if="activeItem.fields && activeItem.fields.includes('produtoEcommerceBox')">
            <el-col :span="24">
                <ProdutosEcommerceBox 
                    :values="values"
                />
            </el-col>
        </el-row>

        <el-row class="row" v-if="activeItem.fields && activeItem.fields.includes('produtosEcommerceBoxCancelamento')">
            <el-col :span="24">
                <ProdutosEcommerceBoxCancelamento 
                    :values="values"
                />
            </el-col>
        </el-row>

        <el-row class="row" v-if="activeItem.fields && activeItem.fields.includes('enderecoBox')">
            <el-col :span="24">
                <EnderecoBox 
                    :values="values"
                />
            </el-col>
        </el-row>

        <el-row class="row" v-if="activeItem.fields && activeItem.fields.includes('mensagem')">
            <el-col :span="24">
                <textarea placeholder="Mensagem" class="input textarea" v-model="values.mensagem"/>
            </el-col>
        </el-row>

    </div>
    
</template>

<script>
    import moment from 'moment';

    import ProdutosBox from './ProdutosBox.vue';
    import ComprovanteBox from './ComprovanteBox.vue';
    import EnderecoBox from './EnderecoBox.vue';
    import ProdutosEcommerceBox from './ProdutosEcommerceBox.vue';
    import ProdutosEcommerceBoxCancelamento from './ProdutosEcommerceBoxCancelamento.vue';

    export default {
        name: 'form-builder',
        components: {ProdutosBox, ComprovanteBox, EnderecoBox, ProdutosEcommerceBox, ProdutosEcommerceBoxCancelamento},
        props: ['origin', 'activeItem', 'values', 'loading'],
        data () {
            return {
                optionsPagamento: [
                    {
                        value: 'Cartão',
                        label: 'Cartão'
                    },{
                        value: 'Boleto',
                        label: 'Boleto'
                    }
                ],
                lojas: []
            }
        },
        computed: {
           
        },
        created: function() {
            var self = this;

            self.axios.get('https://ms.imgnet.com.br/api.sacFrontWorkflow/lojas')
                .then(response => {
                    self.lojas = response.data.map(loja => {
                        return {
                            label: `${loja.FILIAL} - ${loja.UF}`,
                            value: loja.CODIGO
                        }
                    })
                })
                .catch(err => console.error(err))
            
        },
        methods: {
            onNovoProduto: function(cod, qtde, dataCompra){
                this.values.produtos.push({
                    cod, 
                    qtde,
                    dataCompra,
                    timestamp: moment().format()
                })
            },

            onRemoveProduto: function(id){
                this.values.produtos = this.values.produtos.filter(produto => produto.timestamp != id)
            },

            changeLojaMaisProxima: function(item){
                this.values.codFilial = item
            }
        }
    }
</script>

<style>
    #form-builder .row {
        margin-bottom: 1em
    }

    #form-builder .el-input__inner, #form-builder .el-textarea__inner {
        border: none;
        border-bottom: 1px solid #afafaf;
        font-size: 1.2em;
    }

    #form-builder .forma-pgto {
        font-size: 1.2em;
        color: #757575;
    }

    .input {
        width: 100%;
        height: 2.5em;
        border: none;
        border-bottom: 1px solid rgba(175, 175, 175, 0.7);
        margin-bottom: 1em;
        font-size: 1.2em;
        font-family: 'Montserrat';
    }

    .el-select {
        width: 100%;
        height: 2.5em;
        border: none;
        /* border-bottom: 1px solid rgba(175, 175, 175, 0.7); */
        margin-bottom: 1em;
        font-size: 1.2em;
        font-family: 'Montserrat';
    }

    .textarea {
        height: 8em;
    }

    .input:focus {
        outline: none;
    }

    a {
        cursor: pointer;
        color: #7741bb;
        text-decoration: underline;
    }

    .loja-imaginarium-form {
        margin-top: 1em;
        color: #999;
    }
    
</style>
