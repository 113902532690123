<template>
    <div id="form-novo-atendimento">
        
        <modal name="modal-erros">
            <div class="modal">
                <div class="title-modal"> <i class="el-icon-error"></i> Oops, encontramos alguns erros</div>

                <div v-for="error in errors" :key="error">
                    <p>{{error}}</p>
                </div>

                <el-button plain class="btn-voltar" type="success" @click="this.closeModal">Voltar e corrigir os erros</el-button>
            </div>
        </modal>

        <el-col :span="20" :offset="2" v-loading="loading" v-if="!finished" >
            <div v-for="item in this.tipos[origin]" :key="item.cod" class="item" @click=" changeActive( item.cod ) " v-if="!activeId">
                {{item.name}}
            </div>
            <div v-if="activeId">
                <div @click="changeActive( '' )" class="back">
                    <i class="el-icon-arrow-left"></i> <span>voltar</span>
                </div>

                <span class="titulo">
                    Atendimento para {{activeItem.name}} | {{descOrigin}}
                </span>

                <span class="legenda">
                    {{activeItem.legenda}}
                </span>

                <FormBuilder
                    :origin="origin"
                    :activeItem="activeItem"
                    :values="values"
                    :loading="loading"
                />

                <div class="btn">
                    <el-button @click="this.onSubmit" v-loading="this.loading" class="puket-round-btn"><span class="btn-title">Enviar ;)</span></el-button>
                </div>
            </div>            
        </el-col>

        <el-col :span="20" :offset="2" v-if="finished">
            <div class="container-finished">

                <div class="title-finished">
                    ATENDIMENTO REALIZADO
                </div>

                <img src="https://ms.imgnet.com.br/sac/resources/images/yass.png">
                
                <div class="description-finished">
                    Em breve você receberá um e-mail no endereço <span class="email-finished">{{values.email}}</span> com todos os detalhes do atendimento :)
                    <br />
                    Por favor verifique seu lixo eletrônico!
                </div>

            </div>
        </el-col>

    </div>
</template>

<script>
    import FormBuilder from './FormBuilder.vue';

    export default {
        name: 'form-novo-atendimento',
        props: ['origin'],
        components: {FormBuilder},
        data () {
            return {
                loading: false,
                finished: false,
                errors: [],
                activeId: '',
                tipos: {
                    fisica: [
                        {
                            cod: '1',
                            name: 'Troca de produto',
                            fields: ['comprovanteBox', 'produtoBox', 'mensagem'],
                            tipoProcesso: 'duvida',
                            workflow: 'CRMAtendimentoCliente',
                            requiredFields: ['produtoBox', 'mensagem']
                        },
                        // {
                        //     cod: '2',
                        //     name: 'Segunda via do comprovante de compra',
                        //     legenda: 'Será feita uma análise dos dados informados. Caso haja um cupom cadastrado com estes dados, informaremos a forma de reimpressão do mesmo.',
                        //     fields: ['comprovanteBox', 'produtoBox', 'mensagem'],
                        //     tipoProcesso: 'duvida',
                        //     workflow: 'CRMAtendimentoCliente',
                        //     requiredFields: ['produtoBox', 'mensagem']
                        // },
                        // {
                        //     cod: '3',
                        //     name: 'Solicitação de acessórios',
                        //     legenda: 'Será feita uma análise de  disponibilidade de envio do acessório do seu produto. Caso haja a disponibilidade, informaremos a forma de envio e o possível custo dessa operação.',
                        //     fields: ['produtoBox', 'enderecoBox', 'pecaSolicitada', 'mensagem'],
                        //     tipoProcesso: 'PEP',
                        //     workflow: 'CRMASTECGrupoImaginarium',
                        //     requiredFields: ['produtoBox', 'mensagem', 'enderecoBox', 'pecaSolicitada']
                        // },{
                        //     cod: '4',
                        //     name: 'Solicitação de assistência técnica',
                        //     legenda: 'Será feita uma análise de disponibilidade da assistência técnica. Caso haja, informaremos a forma de envio e o custo desta operação.',
                        //     fields: ['produtoBox', 'enderecoBox', 'mensagem', 'comprovanteBox'],
                        //     tipoProcesso: 'duvida',
                        //     workflow: 'CRMASTECGrupoImaginarium',
                        //     requiredFields: ['produtoBox', 'enderecoBox', 'mensagem']
                        // },
                        {
                            cod: '5',
                            name: 'Dúvidas sobre produto',
                            fields: ['produtoBox', 'mensagem'],
                            tipoProcesso: 'duvida',
                            workflow: 'CRMAtendimentoCliente',
                            requiredFields: ['mensagem']
                        },{
                            cod: '6',
                            name: 'Outros',
                            fields: ['mensagem'],
                            tipoProcesso: 'duvida',
                            workflow: 'CRMAtendimentoCliente',
                            requiredFields: ['mensagem']
                        }
                    ],
                    virtual: [
                        {
                            cod: '1',
                            name: 'Troca',
                            legenda: 'O produto devolvido passará por uma análise e, se constatado defeito de fabricação, um novo produto igual ao adquirido, será enviado a você sem custo algum.',
                            fields: ['produtoEcommerceBox'],
                            tipoProcesso: 'troca',
                            workflow: 'CRMAtendimentoCliente',
                            requiredFields: ['produtoEcommerceBox']
                        },{
                            cod: '2',
                            name: 'Devolução',
                            legenda: 'O produto devolvido passará por uma análise e, se constatado defeito de fabricação, será realizado o estorno (para compras no cartão) ou reembolso (para compras no boleto) da compra.',
                            fields: ['produtoEcommerceBox'],
                            tipoProcesso: 'devolucao',
                            workflow: 'CRMAtendimentoCliente',
                            requiredFields: ['produtoEcommerceBox']
                        },{
                            cod: '3',
                            name: 'Dúvidas sobre produto',
                            fields: ['produtoBox', 'mensagem'],
                            tipoProcesso: 'duvida',
                            workflow: 'CRMAtendimentoCliente',
                            requiredFields: ['mensagem']
                        },{
                            cod: '4',
                            name: 'Cancelamento de pedido',
                            legenda: 'O cancelamento do pedido só poderá ser realizado caso o mesmo não tenha sido faturado. ',
                            fields: ['pedidoLojaVirtual','mensagem'],
                            tipoProcesso: 'cancelamento',
                            workflow: 'CRMAtendimentoCliente',
                            requiredFields: ['pedidoLojaVirtual','mensagem']
                        }
                        ,{
                            cod: '5',
                            name: 'Solicitação de assistência técnica',
                            legenda: 'Será feita uma análise de disponibilidade da assistência técnica. Caso haja, informaremos a forma de envio e o custo desta operação.',
                            fields: ['produtoEcommerceBox', 'enderecoBox'],
                            tipoProcesso: 'duvida',
                            workflow: 'CRMASTECGrupoImaginarium',
                            requiredFields: ['produtoEcommerceBox', 'enderecoBox']
                        }
                        // ,{
                        //     cod: '6',
                        //     name: 'Solicitação de acessórios',
                        //     legenda: 'Será feita uma análise de  disponibilidade de envio da parte/peça do seu produto. Caso haja, informaremos a forma de envio e o possível custo desta operação.',
                        //     fields: ['produtoBox', 'pecaSolicitada','enderecoBox', 'mensagem'],
                        //     tipoProcesso: 'PEP',
                        //     workflow: 'CRMASTECGrupoImaginarium',
                        //     requiredFields: ['mensagem', 'produtoBox', 'pecaSolicitada','enderecoBox']
                        // }
                        ,{
                            cod: '7',
                            name: 'Outros',
                            fields: ['pedidoLojaVirtual', 'mensagem'],
                            tipoProcesso: 'duvida',
                            workflow: 'CRMAtendimentoCliente',
                            requiredFields: ['mensagem']
                        }
                    ]
                },
                values: {
                    codFilial: '',
                    cpf: '',
                    nomeCompleto: '',
                    telefone: '',
                    telefoneDois: '',
                    email: '',
                    emailCheck: '',
                    mensagem: '',
                    produtos: [],
                    produtosPedido: [],
                    possuiComprovante: '',
                    comprovante: {
                        data: '',
                        cpf: '',
                        nome: '',
                        loja: ''
                    },
                    pecaSolicitada: '',
                    pecaSolicitadaQuantidade: '',
                    lojaImaginariumMaisProxima: '',
                    enderecoCliente: {
                        rua: '',
                        cep: '',
                        numero: '',
                        complemento: '',
                        bairro: '',
                        cidade: '',
                        uf: ''
                    },
                    marca: 'PUKET',
                    marcaNP: 'PUKET',
                    tipoDevolucao: 'TOTAL',
                    tipoProcesso: '',
                    tipoPagamento: '',
                    pedidoVtex: '',
                    pedidoECM: '',
                    orginForm : 'PORTAL'
                }
            }
        },
        computed: {
           activeItem: function(){
               return this.tipos[this.origin].filter(item => item.cod == this.activeId)[0]
           },

           descOrigin: function(){
               return this.origin == 'fisica' ? 'Loja Física' : 'Loja Virtual';
           }
        },
        created: function() {    
            var self = this;
            
            self.$root.$on('CHANGE_ACTIVEID', (payload) => {
                self.activeId = payload;
            })
        },
        methods: {
            changeActive: function(activeId){
                this.activeId = activeId
            },

            closeModal: function(){
                this.$modal.hide('modal-erros');
            },

            onSubmit: function(){
                var self = this;

                if(!self.isValidForm())
                    return this.$modal.show('modal-erros');

                // self.loading = true

                self.values.workflow = self.activeItem.workflow;
                self.values.tituloAtendimento = self.activeItem.name;
                self.values.procedimento = self.activeItem.name;
                self.values.tipoProcesso = self.activeItem.tipoProcesso;
                self.values.origemCompra = self.origin == 'fisica' ? 'LOJA' : 'SITE';   
                self.values.orginForm = 'PORTAL';

                self.values.pedidoECM = ((!self.values.pedidoECM || self.values.pedidoECM == '') && self.values.pedidoVtex) ? self.values.pedidoVtex : self.values.pedidoECM;

                if(self.activeItem.name != 'Solicitação de acessórios'){
                    self.values.produtos = self.values.produtos.filter(produto => produto.qtde > 0);
                }                

                if(self.values.origemCompra === 'SITE')
                self.values.tipoDevolucao = self.values.produtos.length == self.values.produtosPedido.length ? 'TOTAL' : 'PARCIAL'
                // self.axios.post('https://ms.imgnet.com.br/api.sac/atendimento', self.values)
                self.axios.post('https://ms.imgnet.com.br/api.sac/cronos/atendimento', self.values)
                    .then(response => {
                        console.log(response.data);
                        if(!response.data.success){
                            alert('Oops, algo de errado aconteceu ao salvar seu atendimento. tente novamente mais tarde :)')
                        } else {
                            self.$root.$emit('FINISHED', true)
                            self.finished = true
                        }

                        self.loading = false;
                    })
                    .catch(err => {
                        alert('Oops, algo de errado aconteceu ao salvar seu atendimento. tente novamente mais tarde :)')
                        console.error(err);
                        self.loading = false;
                    })
            },

            isValidForm: function(){
                var self = this;

                self.errors = []

                if(!self.values.cpf)
                    self.errors.push('Preencha o seu CPF')
                
                if(!self.values.email)
                    self.errors.push('Preencha o seu Email')

                if(!self.isValidEmail(self.values.email))
                    self.errors.push('Preencha um e-mail valido')

                if(self.values.email != self.values.emailCheck)
                    self.errors.push('O email inserido não é igual ao email do campo de confirmação')
                
                if(!self.values.telefone)
                    self.errors.push('Preencha o seu telefone')

                if(!self.activeItem.requiredFields)
                    return !self.errors.length;

                if(self.activeItem.requiredFields.indexOf('mensagem') >= 0){
                    if(!self.values.mensagem)
                        self.errors.push('Preencha a sua mensagem')
                }

                if(self.activeItem.requiredFields.indexOf('pedidoLojaVirtual') >= 0){
                    if(!self.values.pedidoVtex)
                        self.errors.push('Preencha o código do seu pedido')
                }

                if(self.activeItem.requiredFields.indexOf('formaPagamento') >= 0){
                    if(!self.values.tipoPagamento)
                        self.errors.push('Preencha o tipo de pagamento')
                }

                if(self.activeItem.requiredFields.indexOf('produtoBox') >= 0){
                    if(!self.values.produtos.length)
                        self.errors.push('Adicione algum produto ao seu atendimento')
                }

                if(self.activeItem.requiredFields.indexOf('produtoEcommerceBox') >= 0){
                    return self.values.produtos.some(produto => produto.qtde > 0)
                }

                // if(self.activeItem.requiredFields.indexOf('produtosEcommerceBoxCancelamento') >= 0){
                //     self.errors.push('Adicione algum produto ao seu atendimento')
                //     return false;
                // }

                if(self.activeItem.requiredFields.indexOf('pecaSolicitada') >= 0){
                    if(!self.values.pecaSolicitada)
                        self.errors.push('Preencha a peça solicitada')
                }

                if(self.activeItem.requiredFields.indexOf('enderecoBox') >= 0){
                    if(!self.values.enderecoCliente.rua || !self.values.enderecoCliente.cep || !self.values.enderecoCliente.numero || !self.values.enderecoCliente.bairro || !self.values.enderecoCliente.cidade || !self.values.enderecoCliente.uf)
                        self.errors.push('Verifique se o endereço inserido está completo')
                }

                return !self.errors.length;
            },

            isValidEmail: function(email){
                return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email)
            }
        },
        watch: {
            'origin': function(val, oldVal){
                this.activeId = '';
            }
        }
    }

</script>

<style scoped>

    .item {
        font-weight: bold;
        cursor: pointer;
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        border-bottom: 1px solid rgba(68, 68, 68, 0.3);
        color: #FFF;
    }

    .back {
        cursor: pointer;
    }

    .btn {
        display: grid;
        height: 5em;
        margin-top: 1em;
    }

    .btn-title {
        font-weight: bold;
        font-size: 1.5em;
        border-radius:60px;
    }

    .el-button {
        border-radius: 60px;
        background-color: #9df1e1;
        border: 4.43px solid #9df1e1;
    }

    .el-button, .el-button:focus, .el-button:hover {
        color: #000;
    }

    .titulo {
        display: block;
        font-weight: bold;
        font-size: 1.5em;        
        margin-bottom: .5em;
        color: #FFF;
    }

    .legenda {
        display: block;
        color: #999;
        margin-bottom: 1em;
    }

    .v--modal {
        height: auto !important;
        padding-bottom: 2em !important;
    }

    .modal {
        margin: 2em;
    }

    .title-modal {
        font-weight: bold;
        text-align: center;
        font-size: 1.5em;
        border-bottom: 1px solid #EC644B;
        padding-bottom: 1em;
    }

    .btn-voltar {
        width: 100%;
        font-size: 1em;
        margin-top: 2em;
    }
    
    .container-finished {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .title-finished {
        font-weight: bold;
        font-size: 1.5em;
    }

    .description-finished {
        text-align: center;
        padding-top: 3em;
        padding-bottom: 4em;
    }

    .email-finished {
        color: #7645bb;
        font-weight: bold;
    }
</style>

