import Vue from 'vue'
import App from './src/App.vue'

// import VueRouter from 'vue-router'
// import { sync } from 'vuex-router-sync'

// import { routes } from './src/routerConfig'
import  router  from './src/router'

import axios from 'axios'
import VueAxios from 'vue-axios'

import ElementUI from 'element-ui'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueScrollTo from 'vue-scrollto'
import vmodal from 'vue-js-modal'
import VueTheMask from 'vue-the-mask'

Vue.use(VueAxios, axios)
// Vue.use(VueRouter)
Vue.use(ElementUI)

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCoAjA-oRBCFOjZIfPte2qjG2oA_XQqCfg',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)
    }
})
Vue.use(VueScrollTo)
Vue.use(vmodal)
Vue.use(VueTheMask)

import store from './src/store.js'

// const router = new VueRouter({
// 	routes,
// 	// mode: 'history', 
// }); 

// sync(store, router);

window.eventHub = new Vue();

window.App = new Vue({
    el: '#app',
    store,
    router,
    // http: { },
    // created: function() {
        
        
    // },
    render: h => h(App)
})

