<template>

    <div id="puket">

        <!-- <img class="banner" src="https://ms.imgnet.com.br/sac/resources/images/banner.png" alt="Banner"> -->
        <el-row>
            <el-col :span="24">
                <NovoAtendimentoCronos />
            </el-col>
        </el-row>
        
    </div>
    
</template>

<script>
    import NovoAtendimentoCronos from '../pages/NovoAtendimentoCronos/NovoAtendimentoCronos.vue'

    export default {
        name: 'Puket',
        components: {NovoAtendimentoCronos},
        data () {
            return {

            }
        },
        computed: {
           
        },
        created: function() {
            
            
        },
        methods: {
                  
        }
    }
</script>

<style scoped>

    .bg-gray {
        background: #f4f4f4;
    }

    .banner {
        width: 100%;
        margin-top: 9em;
    }

</style>
