<template>

    <div id="produtos-ecommerce-box-cancelamento">

        <el-row v-loading="loadingPedidos" class="rowProd">
            <el-col :span="16" class="prodCod">
                <input  v-model="values.pedidoVtex" placeholder="Código do pedido" class="input" />
            </el-col>
             <el-col :span="8">
                 <div class="btn-pesquisar" @click="this.onPesquisarPedidos">
                     <span>Pesquisar</span>
                 </div>
            </el-col>
        </el-row>

    </div>
    
</template>

<script>

    export default {
        name: 'produtos-ecommerce-box-cancelamento',
        components: {},
        props: ['values'],
        data () {
            return {
                loadingPedidos: false,
                options: [
                    {
                        value: 'desistencia',
                        label: 'Desistência'
                    },{
                        value: 'avaria',
                        label: 'Avaria'
                    },{
                        value: 'defeito',
                        label: 'Defeito'    
                    }
                ]
            }
        },
        computed: {
           
        },
        created: function() {
            // console.log(values)
        },
        mounted: function(){
            console.log('Veio aqui canc')
            // console.log(values)
        },
        methods: {
            onPesquisarPedidos: function(){
                var self = this;

                self.loadingPedidos = true;

                self.axios.post('https://ms.imgnet.com.br/api.sac/puket/consulta/pedido', {nf_pedido: self.values.pedidoVtex.trim()})
                    .then(response => {
                        if(!response.data.success) { self.loadingPedidos = false; return console.error(response.data) }
                        console.log(self.values)
                        
                        self.values.produtosPedido = response.data.data;
                        self.values.produtos = response.data.data.map(produto => {
                            return {
                                qtde: 0,
                                cod: produto.PRODUTO,
                                desc: produto.DESC_PRODUTO,
                                dataCompra: produto.DATA,
                                motivo: '',
                                detalhes: ''
                            }
                        })
                        self.values.pedidoECM = response.data.data[0].PEDIDO

                        self.values.tipoPagamento = response.data.data[0].TIPO_PAGAMENTO

                        self.loadingPedidos = false;

                        alert('Pedido encontrado, pode prosseguir com a abertura do atendimento!')
                    })
                    .catch(err => {
                        console.error(err)
                        self.loadingPedidos = false;
                    })
            }
        }
    }
</script>

<style scoped>
    .btn-pesquisar {
        height: 3em;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 0px;
        border: 1px solid #D2D7D3;
        background-color: #eae8e8;
        box-shadow: 5px 3px 10px 0px rgba(0,0,0,.1);
        margin-left: 1em;
        cursor: pointer;
    }

    .btn-pesquisar:active {
        background-color: rgb(179, 179, 179);
    }

    .thumb {
        width: 10em;
    }

    .rowPesquisar {
        display: flex;
        justify-content: space-between;
    }

    .prodCod {
        margin-right: 30px;
    }

    .input-number {
        padding-left: 1em;
    }

    .produtos-container {
        background-color: #eae8e8;
        padding: 1em;
        margin-bottom: 1em;
        display: flex;
        align-items: center;
    }

    .produto-desc {
        margin-bottom: 1em;
        text-align: center;
        font-weight: bold;
    }

    .produto-quantidade, .produto-motivo, .produto-detalhes {
        justify-content: center;
        display: flex;
    }

    .textarea {
        height: 8em;
        width: 90%;
        border: none;
        border-bottom: 1px solid rgba(175, 175, 175, 0.7);
        margin-top: 1em;
        font-size: 1.2em;
        font-family: 'Montserrat';
    }
</style>
