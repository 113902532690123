<template>

    <div id="produtos-box">

        <modal name="modal-novo-produto">
            <div class="modal">
                <el-col :span="24" class="text-box">
                    <span class="text">Adicione um novo produto ao seu atendimento</span>
                </el-col>
                <el-col :span="24">
                    <input class="input" v-model="novoProdutoNome" placeholder="Código ou Descrição do produto" />
                </el-col>
                <el-col :span="24">
                    <input class="input" v-model="novoProdutoDataCompra" placeholder="Data da compra"  v-mask="'##/##/####'"/>
                </el-col>
                <el-col :span="24" v-if="mostrarQuantidade">
                    Quantidade: <span class="input-number"><el-input-number :min="1" :max="999" v-model="novoProdutoQtde"></el-input-number></span>
                </el-col>
                <el-col :span="12" class="actions">
                    <el-button plain class="btn" type="success" @click="this.onNovoProdutoModal">Adicionar</el-button>
                </el-col>
                <el-col :span="12" class="actions">
                    <el-button plain class="btn" type="danger" @click="this.onCancel">Cancelar</el-button>
                </el-col>
            </div>
        </modal>

        <el-col :span="24" class="container-produtos">
            <div v-for="produto in produtos" :key="produto.timestamp" class="produto">
                <p>{{produto.cod}}</p>
                <p class="produto-quantidade">{{produto.qtde}} Un.</p>
                <p class="btn-remove" @click="removeProduct(produto)">REMOVER</p>
            </div>
            <el-button plain class="btn-produto" @click="this.onShowModal">
                <i class="el-icon-plus"></i>
                <p>Adicionar produto</p>
            </el-button>
        </el-col>

    </div>
    
</template>

<script>

    export default {
        name: 'produtos-box',
        components: {},
        props: ['produtos', 'onNovoProduto', 'onRemoveProduto', 'mostrarQuantidade'],
        data () {
            return {
                novoProdutoNome: '',
                novoProdutoQtde: 0,
                novoProdutoDataCompra: ''
            }
        },
        computed: {
           
        },
        created: function() {
            
            
        },
        methods: {
            onShowModal: function(){
                this.$modal.show('modal-novo-produto');
            },

            onHideModal: function(){
                this.$modal.hide('modal-novo-produto');
            },

            onNovoProdutoModal: function(){

                this.onNovoProduto(this.novoProdutoNome, this.novoProdutoQtde, this.novoProdutoDataCompra);

                this.novoProdutoNome = '';
                this.novoProdutoQtde = 0;
                this.novoProdutoDataCompra = '';

                this.$modal.hide('modal-novo-produto');

            },

            removeProduct: function(product) {
                this.onRemoveProduto(product.timestamp)
            },

            onCancel: function(){
                this.novoProdutoNome = '';
                this.novoProdutoQtde = '';

                this.$modal.hide('modal-novo-produto');
            }
        }
    }
</script>

<style>
    .v--modal {
        height: auto !important;
        padding-bottom: 2em !important;
    }

    #produtos-box .modal {
        margin: 2em;
    }

    #produtos-box .input-number {
        padding-left: 1em;
    }

    #produtos-box .actions {
        margin-top: 2em;
        align-items: center;
        text-align: center;
    }

    #produtos-box .btn {
        width: 85%;
    }

    #produtos-box .text {
        font-size: 1.2em;
        font-weight: bold;
    }

    #produtos-box .text-box {
        text-align: center;
        margin-bottom: 1em;
    }

    #produtos-box .el-button {
        border-radius: 0px;
        border: 1px solid #D2D7D3;
        background-color: #FFF;
        box-shadow: 5px 3px 10px 0px rgba(0,0,0,.1);
    }

    #produtos-box .el-button, .el-button:focus, .el-button:hover {
        color: #000;
    }

    #produtos-box .container-produtos {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        background-color: #f5f5f5;
    }

    #produtos-box .produto {
        margin: 1em;
        border: 1px solid #D2D7D3;
        padding: 1em;
        background-color: #FFF;
        box-shadow: 5px 3px 10px 0px rgba(0,0,0,.1);
    }

    #produtos-box .produto-quantidade {
        font-style: italic;
    }

    #produtos-box .btn-remove {
        text-align: center;
        cursor: pointer;
        background-color: #ef7e6a;
        padding: .5em;
    }

    #produtos-box .btn-produto {
        height: 5em;
        width: 12em;
        margin: 1em;
    }
</style>
