<template>

    <el-container id="endereco-box">
        <el-main v-loading="this.loading">

            <el-row :gutter="30">
                <el-col :xs="24" :sm="24" :md="24" :lg="5" :xl="5">
                    <input  v-model="values.enderecoCliente.cep" placeholder="CEP" class="input" @keyup="this.onChangeCEP"/>
                </el-col>
                <el-col :xs="24" :sm="24" :md="19" :lg="11" :xl="11">
                    <input  v-model="values.enderecoCliente.rua" placeholder="Rua" class="input"/>
                </el-col>
                <el-col :xs="24" :sm="24" :md="5" :lg="2" :xl="2">
                    <input  v-model="values.enderecoCliente.numero" ref="numero" placeholder="Nº" class="input"/>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    <input  v-model="values.enderecoCliente.bairro" placeholder="Bairro" class="input"/>
                </el-col>
            </el-row>

            <el-row :gutter="30">
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <input  v-model="values.enderecoCliente.complemento" placeholder="Complemento" class="input"/>
                </el-col>
                <el-col :xs="24" :sm="24" :md="19" :lg="9" :xl="9">
                    <input  v-model="values.enderecoCliente.cidade" placeholder="Cidade" class="input" :disabled="isCepExiste"/>
                </el-col>
                <el-col :xs="24" :sm="24" :md="5" :lg="3" :xl="3">
                    <input  v-model="values.enderecoCliente.uf" placeholder="UF" class="input" :disabled="isCepExiste"/>
                </el-col>
            </el-row>

        </el-main>
    </el-container>
    
</template>

<script>

    export default {
        name: 'endereco-box',
        components: {},
        props: ['values'],
        data () {
            return {
                loading: false,
                isCepExiste: false
            }
        },
        computed: {
           
        },
        created: function() {
            
            
        },
        methods: {
            onChangeCEP: function(e){
                var self = this;

                if(self.values.enderecoCliente.cep.length == 8){
                    self.loading = true;
                    self.isCepExiste = false;

                    self.axios.get(`https://viacep.com.br/ws/${self.values.enderecoCliente.cep}/json/`)
                        .then(response => {
                            if(response.status == 200){
                                self.values.enderecoCliente.rua = response.data.logradouro;
                                self.values.enderecoCliente.bairro = response.data.bairro;
                                self.values.enderecoCliente.cidade = response.data.localidade;
                                self.values.enderecoCliente.uf = response.data.uf;
                                self.values.enderecoCliente.complemento = '';
                                self.values.enderecoCliente.numero = '';

                                self.$refs.numero.focus();

                                self.isCepExiste = true;
                            } else {
                                self.isCepExiste = false;
                            }        

                            self.loading = false;
                        })
                        .catch(err => {
                            console.error(err);
                            self.loading = false;
                            self.isCepExiste = false;
                        })
                }
            }
        }
    }
</script>

<style scoped>
   
</style>
