import Vue from 'vue';
import Router from 'vue-router';
import Imaginarium from './components/Imaginarium.vue';
import Puket from './components/Puket.vue';


Vue.use(Router);

var router = new Router({
  // mode: 'history',
  routes: [
    
    {
      path: '/',
      name: 'imaginarium',
      component: Imaginarium
    },
    {
      path: '/puket',
      name: 'puket',
      component: Puket
    },

  ]
})


export default router
