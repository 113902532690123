<template>

    <div id="topbar">
        <img :src="image" alt="Logotipo">

        <div class="line"></div>

        <el-menu 
            v-if="showMenu"
            :default-active="activeIndex" 
            class="top-menu" 
            mode="horizontal"
            text-color="#000">
            
           <!--  <div class="top-menu-item"><el-menu-item index="1" v-scroll-to="{ el: '#depoimentos', offset: -200 }">Comentários</el-menu-item></div> -->
            <div class="top-menu-item"><el-menu-item index="6" v-scroll-to="{ el: '#novo-atendimento', offset: -200 }">Fale conosco</el-menu-item></div>
            <!--<div class="top-menu-item"><el-menu-item index="2" v-scroll-to="{ el: '#faq' , offset: -200}">Perguntas frequentes</el-menu-item></div> -->
            <div class="top-menu-item"><el-menu-item index="3" v-scroll-to="{ el: '#manuais', offset: -200 }">Manuais dos produtos</el-menu-item></div>
            <div class="top-menu-item"><el-menu-item index="4" v-scroll-to="{ el: '#lojas', offset: -10 }">Nossas lojas</el-menu-item></div>            
            <!-- <div class="top-menu-item"><el-menu-item index="5" v-scroll-to="{ el: '#acompanhar-atendimento', offset: -200 }">Acompanhar atendimento</el-menu-item></div>  -->          
            

        </el-menu>
        <div class="line"></div>
    </div>
    
</template>

<script>
    
    export default {
        name: 'topbar',
        props: ['image', 'showMenu'],
        components: {},
        data () {
            return {
               activeIndex: '1'
            }
        },
        computed: {
           
        },
        created: function() {
            
            
        },
        methods: {
                  
        }
    }
</script>

<style scoped>
    img {
        display: block;
        margin: 1.5em auto 1.5em auto;
    }

    .el-menu, .top-menu, .el-menu--horizontal{
        position: fixed;
        width: 100%;
        display: flex;
        background-color: #FFF;
        z-index: 2;
    }

    .top-menu-item {
        text-align: center;
        flex: 1;
    }

    .el-menu-item.is-active {
        border-bottom: transparent;
        color: #000; 
    }

    .line {
        border: .6px solid #b1b3b5;
        margin-top: 1px;
    }

    #topbar {
        position: fixed;
        width: 100%;
        z-index: 3;
        background-color: #FFF;
    }
</style>
