<template>

    <div id="faq-lf">
        <h2 @click="setActiveItemFAQ('compras')" class="row">Compras</h2>
        <el-collapse v-model="activeCompras" accordion v-if="activeItemFAQ == 'compras'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Como comprar os produtos Imaginarium?</span>
                </template>
                <div>Você tem duas opções, nossas lojas franqueadas distribuídas em todo o território nacional. Navegue até a seção <a v-scroll-to="{ el: '#lojas' }">nossas lojas</a>, encontre a loja mais próxima e realize uma visita ou realize suas compras através da nossa loja virtual, <a href="https://loja.imaginarium.com.br/" target="_blank">clicando aqui</a>.</div>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">Todos os produtos disponíveis nas lojas físicas, também são encontrados na loja virtual? </span>
                </template>
                <div>Alguns produtos e marcas vendidas na loja virtual são exclusivos, ou seja, não estarão disponíveis nos estoques das nossas franquias, além de que alguns produtos podem esgotar mais rapidamente em uma das duas lojas.</div>
            </el-collapse-item>
            <el-collapse-item name="3">
                <template slot="title">
                    <span class="title">O preço de venda dos produtos é o mesmo em todas as lojas da Imaginarium? </span>
                </template>
                <div>Lojas físicas são franquias da marca Imaginarium e podem apresentar variações no preço dos produtos, conforme a região, não ultrapassando 20% do valor, ou seja, o preço pode variar entre as lojas físicas e também na nossa loja virtual. </div>
            </el-collapse-item>
            <el-collapse-item name="4">
                <template slot="title">
                    <span class="title">Posso comprar na loja virtual e retirar em uma loja física?</span>
                </template>
                <div>Essa opção ainda não está disponível para todo o Brasil. Para saber se já está disponível na sua cidade basta simular uma compra em nossa loja virtual e informar o seu CEP no carrinho de compras.</div>
            </el-collapse-item>
        </el-collapse>

        <!---->

        <h2 @click="setActiveItemFAQ('produtos')" class="row">Produtos</h2>
        <el-collapse v-model="activeProdutos" accordion v-if="activeItemFAQ == 'produtos'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Onde encontro as especificações do produto?</span>
                </template>
                <div>Ao acessar a página de um produto na nossa <a href="https://loja.imaginarium.com.br/" target="_blank">loja virtual</a>, logo abaixo de sua foto são apresentadas as especificações do produto.</div>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">Como eu consulto o estoque de uma loja física?</span>
                </template>
                <div>O estoque individual de cada franquia deve ser verificado diretamente com a loja. Navegue até a seção <a v-scroll-to="{ el: '#lojas' }">nossas lojas</a>, encontre a loja mais próxima e realize um contato ou visita.</div>
            </el-collapse-item>
            <el-collapse-item name="3">
                <template slot="title">
                    <span class="title">Qual o prazo de garantia do meu produto?</span>
                </template>
                <p>Todos os produtos Imaginarium possuem um prazo de garantia legal de 90 dias, contados a partir da data de compra, conforme o Código de Defesa do Consumidor. A garantia dos produtos cobre apenas defeitos de fabricação, ou seja, itens avariados (quebrados, trincados, lascados, rachados, etc) não receberão direito a análise ou troca.</p>
                <p>A garantia será validada mediante apresentação de cupom fiscal ou cupom de troca.</p>
            </el-collapse-item>
            <el-collapse-item name="4">
                <template slot="title">
                    <span class="title">A Imaginarium realiza personalização de produtos?</span>
                </template>
                <div>Não. Os produtos da Imaginarium são personalizados e exclusivos.</div>
            </el-collapse-item>
        </el-collapse>

        <!---->

        <h2 @click="setActiveItemFAQ('troca')" class="row">Troca</h2>
        <el-collapse v-model="activeTroca" accordion v-if="activeItemFAQ == 'troca'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Meu produto apresentou um defeito de fabricação. Como devo proceder para receber a garantia do item? </span>
                </template>
                <p>A garantia dos produtos Imaginarium cobre apenas defeitos de fabricação. Segue abaixo as condições para concessão da garantia:</p>
                <p>1 - Apresentação do cupom fiscal, cupom de troca ou cartão de garantia que comprove que o produto está dentro do prazo de garantia.</p>
                <p>2 - Não será concedida garantia a produtos com avarias ocasionadas por impacto, tendo em vista que todos os produtos são vistos pelo cliente antes de serem colocados na sacola. Sendo assim, produtos com estas características não serão aceitos pela loja.</p>
                <p>3 - No caso de produtos com defeito de fabricação, a unidade franqueada testará o produto junto com o cliente, certificando-se que o defeito não é por falta de conhecimento do uso do produto. Após os testes, caso o produto realmente possua o defeito de funcionamento indicado pelo consumidor, a loja iniciará o processo de envio do produto para análise do defeito pelo setor de Qualidade. O prazo de análise concedido pelo Código de Defesa do Consumidor é de 30 dias.</p>
                <p>*Caso o produto seja um eletrônico atendido por nossa Assistência Técnica, o envio para análise de garantia e/conserto, será através do próprio cliente, abrindo um Atendimento de Solicitação de Assistência Técnica na nossa <span @click="goToAtendimento('fisica', 4)">central de atendimento</span>. A loja ou a equipe de Atendimento irão passar todas as informações sobre o procedimento. Ver mais detalhes na categoria "Assistência Técnica"</p>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">Ganhei um presente, mas gostaria de trocá-lo. Como devo proceder?</span>
                </template>
                <div>Neste caso a troca não é obrigatória, ou seja, será uma análise de cortesia por parte da unidade franqueada. Para tentar negociar esse tipo de troca, o produto deve permanecer sem indícios de uso, em perfeito estado de conservação, dentro de sua embalagem original com todos seus acessórios e manuais. O prazo para este tipo de troca é de 30 dias a partir da data de compra, mediante apresentação de cupom fiscal ou cupom de troca. O Código de Defesa do Consumidor assegura essa prática.</div>
            </el-collapse-item>
            <el-collapse-item name="3">
                <template slot="title">
                    <span class="title">Preciso trocar meu produto, mas não possuo o comprovante da compra. Como proceder?</span>
                </template>
                <p>Caso o produto tenha sido adquirido em uma loja diferente da unidade onde a troca será realizada, é necessário abrir um atendimento na nossa <span @click="goToAtendimento('fisica', 2)">central de atendimento</span>, e solicitar a reimpressão do cupom de trocas, informando os seguintes dados: Data exata da compra; Loja onde o produto foi adquirido; Modelo do produto; Nome completo e CPF do comprador.</p>
            </el-collapse-item>
            <el-collapse-item name="4">
                <template slot="title">
                    <span class="title">Posso comprar em uma loja física e trocar na loja virtual? </span>
                </template>
                <div>Não. Os produtos comprados nas nossas lojas físicas só podem ser trocados em lojas físicas, pois o estoque e os procedimentos fiscais são diferentes entre nossa rede de franquias e nosso e-commerce. </div>
            </el-collapse-item>
        </el-collapse>

        <!---->

        <h2 @click="setActiveItemFAQ('geral')" class="row">Geral</h2>
        <el-collapse v-model="activeGeral" accordion v-if="activeItemFAQ == 'geral'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Como dar uma sugestão para a Imaginarium?</span>
                </template>
                <div>Para passar sua opinião ou sugestão entre em contato com a nossa <a v-scroll-to="{ el: '#novo-atendimento' }"><span @click="goToAtendimento('fisica', 6)">central de atendimento</span></a></div>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">Como obter informações para realizar meu trabalho escolar/acadêmico?</span>
                </template>
                <div>Todas as informações sobre a Imaginarium poderão ser obtidas através do endereço de e-mail marketing@imaginarium.com.br</div>
            </el-collapse-item>
            <el-collapse-item name="3">
                <template slot="title">
                    <span class="title">Vocês vendem seus produtos para colocar em minha loja, fora do sistema de franquias?</span>
                </template>
                <div>Sim. Caso tenha interesse em se tornar um parceiro, <a href="http://portal.imaginarium.com.br/namedida/fale-conosco" target="_blank">clique aqui</a> e preencha seu cadastro.</div>
            </el-collapse-item>
            <el-collapse-item name="4">
                <template slot="title">
                    <span class="title">Como oferecer o produto e/ou o serviço de minha empresa para Imaginarium?</span>
                </template>
                <div>Para oferecer seus serviços para a Imaginarium, você deve encaminhar uma mensagem para o e-mail fornecedores@imaginarium.com.br</div>
            </el-collapse-item>
            <el-collapse-item name="5">
                <template slot="title">
                    <span class="title">Como faço para me tornar um franqueado?</span>
                </template>
                <div>Por meio da página <a href="http://portal.imaginarium.com.br/seja-um-franqueado/" target="_blank">seja um franqueado</a>, você conhecerá melhor como funciona a franquia Imaginarium e poderá realizar seu cadastro.</div>
            </el-collapse-item>
            <el-collapse-item name="6">
                <template slot="title">
                    <span class="title">Como participar de um processo seletivo da Imaginarium?</span>
                </template>
                <div>Para fazer parte do time Imaginarium, acesse a página <a href="https://site.vagas.com.br/imaginarium" target="_blank">trabalhe conosco</a> e cadastre seu currículo. Caso surja uma oportunidade com o seu perfil, a empresa entrará em contato.</div>
            </el-collapse-item>
        </el-collapse>

        <!---->
        
       <h2 @click="setActiveItemFAQ('astec')" class="row">Assistência Técnica</h2>
        <el-collapse v-model="activeASTEC" accordion v-if="activeItemFAQ == 'astec'">
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">Como funciona a Assistência Técnica Imaginarium?</span>
                </template>
                <div>A Imaginarium conta com uma assistência técnica autorizada que realiza o conserto de grande parte dos nossos produtos eletrônicos. Para obter maiores informações sobre a possibilidade de conserto do seu item, entre em contato em nossa <a v-scroll-to="{ el: '#novo-atendimento' }"><span @click="goToAtendimento('fisica', 4)">central de atendimento</span></a> e preencha o cadastro com todas as informações solicitadas. Será feita uma análise de disponibilidade da assistência técnica. Caso haja, informaremos a forma de envio e o possível custo desta operação.</div>
            </el-collapse-item>
            <el-collapse-item name="2">
                <template slot="title">
                    <span class="title">Posso consertar meu produto Imaginarium por conta própria?</span>
                </template>
                <div>A Imaginarium trabalha para oferecer a melhor qualidade em seus produtos, por isso recomenda que todos os reparos sejam realizados pela Assistência Técnica Autorizada da marca. Reparos fora do agente autorizado irão invalidar a garantia do produto.</div>
            </el-collapse-item>
            <el-collapse-item name="3">
                <template slot="title">
                    <span class="title">Onde posso comprar peças de reposição originais?</span>
                </template>
                <div>Peças para reposição de alguns produtos Imaginarium estão disponíveis em nossa Assistência Técnica autorizada. A solicitação deve ser feita em nossa <a v-scroll-to="{ el: '#novo-atendimento' }"><span @click="goToAtendimento('fisica', 3)">central de atendimento</span></a>. Será feita uma análise de disponibilidade de envio da parte/peça do seu produto. Caso haja, informaremos a forma de envio e o possível custo desta operação.</div>
            </el-collapse-item>
            <el-collapse-item name="4">
                <template slot="title">
                    <span class="title">Tenho dúvidas na utilização do produto, o que devo fazer?</span>
                </template>
                <div>Consulte o manual de produto para mais informações sobre a utilização correta do modelo que você adquiriu. Caso não tenha o manual, baixe a versão em PDF que está disponível na seção de <a v-scroll-to="{ el: '#manuais' }">manuais</a>. Caso não encontre o arquivo correspondente ao seu produto faça sua solicitação em nossa <a v-scroll-to="{ el: '#novo-atendimento' }"><span @click="goToAtendimento('fisica', 5)">central de atendimento</span></a>.</div>
            </el-collapse-item>
        </el-collapse>
         
    </div>
    
</template>

<script>

    export default {
        name: 'faq-lf',
        components: {},
        data () {
            return {
                activeCompras: '',
                activeProdutos: '',
                activeTroca: '',
                activeGeral: '',
                activeAssistenciaTecnica: '',
                activeASTEC: '',
                activeItemFAQ: ''
            }
        },
        computed: {
           
        },
        created: function() {
            
            
        },
        methods: {
            goToAtendimento: function(origem, codFormulario){
                this.$root.$emit('START_ATENDIMENTO', {origem, codFormulario});
            },

            setActiveItemFAQ: function(item){
                this.activeItemFAQ = item
            }
        }
    }
</script>

<style scoped>
    #faq-lf {
        margin-bottom: 5em;
    }

    .title {
        font-weight: bold;
    }

    a {
        cursor: pointer;
        color: #7741bb;
        text-decoration: underline;
    }

    .row {
        cursor: pointer;
        border-bottom: 1px solid #D2D7D3;
        padding-bottom: 1em;
    }
</style>
